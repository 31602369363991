<!-- src/components/NotFound.vue -->
<template>
    <div class="not-found">
      <h1>404</h1>
      <p>Oops! A página que você está procurando não foi encontrada.</p>
      <router-link to="/">Voltar para a página inicial</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  }
  </script>
  
  <style scoped>
  .not-found {
    text-align: center;
    padding: 50px;
  }
  </style>
  