import { render, staticRenderFns } from "./AppSimulado.vue?vue&type=template&id=136ea894&scoped=true"
import script from "./AppSimulado.vue?vue&type=script&lang=js"
export * from "./AppSimulado.vue?vue&type=script&lang=js"
import style0 from "./AppSimulado.vue?vue&type=style&index=0&id=136ea894&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136ea894",
  null
  
)

export default component.exports