<template>
    <div>
        <div class="home-container">
            <div class="home-title">Por que escolher <br>a Aprojur OAB?</div>
            <div style="width: 135px; height: 3px; background-color: #B99660; margin: 15px 0;"></div>
            <div style="font-family: 'Montserrat Regular 400'">Além de pensar em soluções e ensinar de um jeito diferente; <br> Você pode escolher a Aprojur OAB por várias outras razões:</div>
        </div>
            
        <div class="panel-navigation">
            <div class="panel-container" ref="panelContainer">
                <div class="panel-cards" style="margin-left: 10vw;"><img src="@/assets/pqEscolher/Card 01.png" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/pqEscolher/Card 02.png" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/pqEscolher/Card 03.png" width="100%"></div>
                <div class="panel-cards" style="margin-right: 10vw;"><img src="@/assets/pqEscolher/Card 04.png" width="100%"></div>
            </div>
            <button @click="scrollLeft" class="scroll-button scroll-button-left"><i class="pi pi-chevron-left" /></button>
            <button @click="scrollRight" class="scroll-button scroll-button-right"><i class="pi pi-chevron-right" /></button>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
            SCROLL_PX: 420
        };
    },
    methods: {
        scrollLeft() {
            this.$refs.panelContainer.scrollBy({
                left: -this.SCROLL_PX,
                behavior: 'smooth'
            });
        },
        scrollRight() {
            this.$refs.panelContainer.scrollBy({
                left: this.SCROLL_PX,
                behavior: 'smooth'
            });
        }
    }
};
</script>

<style scoped>

</style>