var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"panel-navigation"},[_c('div',{ref:"panelContainer",staticClass:"panel-container"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14)]),_c('button',{staticClass:"scroll-button scroll-button-left",on:{"click":_vm.scrollLeft}},[_c('i',{staticClass:"pi pi-chevron-left"})]),_c('button',{staticClass:"scroll-button scroll-button-right",on:{"click":_vm.scrollRight}},[_c('i',{staticClass:"pi pi-chevron-right"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-container"},[_c('div',{staticClass:"home-title"},[_vm._v("Aprojur: "),_c('br'),_vm._v(" Uma Experiência Única, "),_c('br'),_vm._v(" Feita para Você.")]),_c('div',{staticStyle:{"width":"135px","height":"3px","background-color":"#B99660","margin":"15px 0"}}),_c('div',{staticStyle:{"font-family":"'Montserrat Regular 400'"}},[_vm._v("Confira nossos diferenciais do Curso Premium Online de 1ª Fase da OAB: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"margin-left":"10vw"}},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 01.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 02.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 03.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 04.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 05.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 06.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 07.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 08.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 09.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 10.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 11.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 12.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards"},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 13.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"margin-right":"10vw"}},[_c('img',{attrs:{"src":require("@/assets/experiencia/Card 14.svg"),"width":"100%"}})])
}]

export { render, staticRenderFns }