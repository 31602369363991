<template>
    <div style="background-color: white;">
        <Toast />

        <div class="container">
            <div class="title">Crie sua conta!</div>

            <!-- Nome -->
            <div class="item-style">
                <span>Nome *</span><br>
                <InputText class="w-100" v-model="formRegister.name" />
                <div v-if="!isValidForm && isValidValue(formRegister.name)" class="p-error">Campo obrigatório.</div>
            </div>
            
            <!-- Login -->
            <div class="item-style">
                <span>E-mail *</span><br>
                <InputText class="w-100" v-model="formRegister.login" />
                <div v-if="!isValidForm && isValidValue(formRegister.login)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- Senha -->
            <div class="item-style">
                <span>Senha *</span><br>
                <Password class="w-100" v-model="formRegister.password" :feedback="false" toggleMask/>
                <div v-if="!isValidForm && isValidValue(formRegister.password)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- Repetir a Senha -->
            <div class="item-style">
                <span>Repita a Senha *</span><br>
                <Password class="w-100" v-model="repeatPassword" :feedback="false" toggleMask/>
                <div v-if="!isValidForm && isValidValue(repeatPassword)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- CPF -->
            <div class="item-style">
                <span>CPF *</span><br>
                <InputMask class="w-100" mask="999.999.999-99"  v-model="formRegister.cpf" />
                <div v-if="!isValidForm && isValidValue(formRegister.cpf)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- Telefone -->
            <div class="item-style">
                <span>Telefone *</span><br>
                <InputMask class="w-100" mask="(99) 99999-9999" v-model="formRegister.fone" />
                <div v-if="!isValidForm && isValidValue(formRegister.fone)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- Data de Nascimento -->
            <div class="item-style">
                <span>Data de Nascimento *</span><br>
                <InputMask class="w-100" mask="99/99/9999" v-model="formRegister.data_nasc" />
                <div v-if="!isValidForm && isValidValue(formRegister.data_nasc)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- Sexo -->
            <div class="item-style">
                <span>Sexo *</span><br>
                <Dropdown class="w-100" v-model="formRegister.sexo" :options="sexo" optionLabel="name" optionValue="name" />
                <div v-if="!isValidForm && isValidValue(formRegister.sexo)" class="p-error">Campo obrigatório.</div>
            </div>
            
            <!-- CEP -->
            <div class="item-style">
                <span>CEP *</span><br>
                <InputMask class="w-100" mask="99999-999" v-model="formRegister.cep" />
                <div v-if="!isValidForm && isValidValue(formRegister.cep)" class="p-error">Campo obrigatório.</div>
            </div>

            <div class="button-style" @click="consultarCEP">Consultar CEP</div>
            
            <!-- Estado -->
            <div class="item-style">
                <span>Estado *</span><br>
                <InputText class="w-100" v-model="formRegister.estado" />
                <div v-if="!isValidForm && isValidValue(formRegister.estado)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- Cidade -->
            <div class="item-style">
                <span>Cidade *</span><br>
                <InputText class="w-100" v-model="formRegister.cidade" />
                <div v-if="!isValidForm && isValidValue(formRegister.cidade)" class="p-error">Campo obrigatório.</div>
            </div>
            
            <!-- Rua -->
            <div class="item-style">
                <span>Endereço *</span><br>
                <InputText class="w-100" v-model="formRegister.rua" />
                <div v-if="!isValidForm && isValidValue(formRegister.rua)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- Número -->
            <div class="item-style">
                <span>Número *</span><br>
                <InputText class="w-100" v-model="formRegister.num" />
                <div v-if="!isValidForm && isValidValue(formRegister.num)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- BAIRRO -->
            <div class="item-style">
                <span>Bairro/Localidade *</span><br>
                <InputText class="w-100" v-model="formRegister.bairro" />
                <div v-if="!isValidForm && isValidValue(formRegister.bairro)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- COMPLEMENTO -->
            <div class="item-style">
                <span>Complemento *</span><br>
                <InputText class="w-100" v-model="formRegister.complemento" />
                <div v-if="!isValidForm && isValidValue(formRegister.complemento)" class="p-error">Campo obrigatório.</div>
            </div>

            <!-- Instituição de Ensino -->
            <div class="item-style">
                <span>Instituição de Ensino *</span><br>
                <InputText class="w-100" v-model="formRegister.instituicao_de_ensino" />
                <div v-if="!isValidForm && isValidValue(formRegister.instituicao_de_ensino)" class="p-error">Campo obrigatório.</div>
            </div>

            <div class="text-termos">
                <Checkbox v-model="checkBoxTermoDeUso" :binary="true" />
                <span> Li e estou de acordo com o </span>
                <span style="color: blue; text-decoration: underline;" @click="dialogTermos = true">
                    Termo de Uso e Política de Privacidade
                </span>
            </div>
            
            <div class="button-style" @click="submitRegister">Cadastrar-se</div>
        </div>
        <div class="copyright">Copyright 2024 Aprojur. Todos os direitos reservados. Aprojur Preparação Jurídica - LTDA | CNPJ: 54.852.293/0001-64</div>

        <b-modal v-model="dialogTermos" size="lg" centered class="custom-dialog">
            <div style="height: 500px; overflow: auto;">
                <div class="section">
                    <h2>1. Aceitação dos Termos</h2>
                    <p>Ao acessar e utilizar a plataforma AproJur, você concorda em cumprir e estar vinculado aos seguintes Termos de Uso. Caso não concorde com estes termos, não utilize nossa plataforma.</p>
                </div>
                <div class="section">
                    <h2>2. Cadastro e Acesso</h2>
                    <p>2.1. Para acessar os cursos oferecidos, o usuário deve se cadastrar fornecendo informações verdadeiras e completas.</p>
                    <p>2.2. O usuário é responsável por manter a confidencialidade de suas credenciais de acesso e por todas as atividades que ocorrem em sua conta.</p>
                </div>
                <div class="section">
                    <h2>3. Pagamento</h2>
                    <p>3.1. O acesso aos cursos é condicionado ao pagamento das taxas estabelecidas.</p>
                    <p>3.2. Todos os pagamentos são processados por meio de nossos parceiros de pagamento seguro. Não armazenamos informações de cartão de crédito.</p>
                    <p>3.3. As taxas pagas não são reembolsáveis, exceto em casos específicos conforme descrito em nossa política de reembolso.</p>
                </div>
                <div class="section">
                    <h2>4. Uso da Plataforma</h2>
                    <p>4.1. O usuário se compromete a utilizar a plataforma de maneira ética e legal.</p>
                    <p>4.2. É proibido compartilhar o conteúdo dos cursos, exceto com permissão expressa por escrito de AproJur.</p>
                    <p>4.3. Qualquer tentativa de hackear ou explorar vulnerabilidades da plataforma resultará na suspensão imediata da conta e possíveis ações legais.</p>
                </div>
                <div class="section">
                    <h2>5. Direitos Autorais</h2>
                    <p>5.1. Todo o conteúdo disponibilizado na plataforma, incluindo vídeos, textos, imagens e materiais de apoio, é protegido por direitos autorais.</p>
                    <p>5.2. O usuário não pode reproduzir, distribuir ou criar obras derivadas de qualquer conteúdo sem a autorização prévia e por escrito de AproJur.</p>
                </div>
                <div class="section">
                    <h2>6. Limitação de Responsabilidade</h2>
                    <p>6.1. AproJur não se responsabiliza por qualquer dano direto ou indireto resultante do uso ou da incapacidade de uso da plataforma.</p>
                    <p>6.2. Não garantimos que os cursos atenderão às expectativas ou que serão livres de erros.</p>
                </div>
                <div class="section">
                    <h2>7. Modificações nos Termos de Uso</h2>
                    <p>7.1. Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. As mudanças serão comunicadas aos usuários e entrarão em vigor imediatamente após a publicação.</p>
                </div>
                <div class="section">
                    <h2>8. Política de Privacidade</h2>
                    <p>8.1. O uso da plataforma também é regido por nossa Política de Privacidade, que descreve como coletamos, usamos e protegemos as informações pessoais dos usuários.</p>
                </div>
                <div class="section">
                    <h2>9. Disposições Gerais</h2>
                    <p>9.1. Estes Termos de Uso constituem o acordo completo entre o usuário e AproJur e substituem todos os acordos anteriores.</p>
                    <p>9.2. Caso alguma disposição destes Termos de Uso seja considerada inválida, as demais disposições continuarão em pleno vigor e efeito.</p>
                </div>
            </div>
            <template v-slot:modal-footer>
                <b-button variant="secondary" @click="dialogTermos = false">Fechar</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import apiClient from '@/services/api'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Toast from 'primevue/toast';
import InputMask from 'primevue/inputmask';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

export default {
    components: {
        InputText,
        Password,
        Toast,
        InputMask,
        Dropdown,
        Checkbox
    },
    created(){
        let storedData = localStorage.getItem('dataToRegister');
        if (storedData) {
            let data = JSON.parse(storedData)
            this.formRegister.password = data.password;
            this.formRegister.name = data.name;
            this.formRegister.login = data.login;

            localStorage.removeItem('dataToRegister')
        }
    },
    data() {
        return {
            formRegister: {
                login: '',
                name: '',
                cpf: '',
                fone: '',
                data_nasc: '',
                sexo: '',
                estado: '',
                cidade: '',
                rua: '',
                num: '',
                cep: '',
                instituicao_de_ensino: '',
                bairro: '',
                complemento: '',
                password: ''
            },
            sexo: [
                {name: 'Feminino'},
                {name: 'Masculino'}
            ],
            checkBoxTermoDeUso: false,
            dialogTermos: false,
            isValidForm: true,
            repeatPassword: ''
        };
    },
    methods: {
        async submitRegister() {
            for (let key in this.formRegister) {
                if (this.formRegister[key] === '') {
                    this.isValidForm = false;
                    this.$toast.add({ severity: 'warn', summary: "Alerta", detail: `Preencha todos os campos!`, life: 3000 });
                    return;
                }
            }

            if(this.repeatPassword != this.formRegister.password){
                this.$toast.add({ severity: 'warn', summary: "Alerta", detail: `As senhas devem ser iguais!`, life: 3000 });
                return
            }

            if(!this.checkBoxTermoDeUso){
                this.$toast.add({ severity: 'warn', summary: "Alerta", detail: 'Aceite o Termo de Uso e Política de Privacidade', life: 3000 });
                return
            }

            try {
                let response = await apiClient.post('/auth/register', this.formRegister);
                localStorage.setItem('userId', response.data.userId);

                if (localStorage.getItem('product')) {
                    this.$router.push('/payment');
                } else {
                    this.$router.push('/plans');
                }
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.error, life: 3000 });
                    return
                }
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: "Erro interno no servidor", life: 3000 });
            }
        },
        forgotPassword(){
            this.$router.push('/forgotPassword');
        },
        async consultarCEP() {
            const cep = this.formRegister.cep.replace(/\D/g, '');
            try {
                const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
                const data = await response.json();
                
                if (data.erro) {
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível encontrar o CEP', life: 3000 });
                    this.formRegister.rua = '';
                    this.formRegister.bairro = '';
                    this.formRegister.cidade = '';
                    this.formRegister.estado = '';
                    this.errorMessage = '';
                } else {
                    this.formRegister.rua = data.logradouro;
                    this.formRegister.bairro = data.bairro;
                    this.formRegister.cidade = data.localidade;
                    this.formRegister.estado = data.uf;
                    this.errorMessage = '';
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível encontrar o CEP', life: 3000 });
                this.formRegister.rua = '';
                this.formRegister.bairro = '';
                this.formRegister.cidade = '';
                this.formRegister.estado = '';
            }
        },
        isValidValue(val){
            return val.trim() === ''
        }
    }
};
</script>

<style scoped>

.container{
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.item-style{
    text-align: left;
    margin-top: 15px;
}

.w-100{
    width: 100%;
}

.title{
    padding-top: 30px;
    margin: 0 0 10px 0;
    font-size: 25px;
    font-weight: bold;
}

.button-style{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 150px;
    color: #00A3B4;
    margin: 25px auto 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}

.copyright{
    width: 100%;
    padding: 0 10%;
    text-align: center;
    margin: 20px 0;
}

.text-termos{
    font-size: 14px;
    margin: 25px 20px 0 20px;
}

</style>