<template>
    <div>
        <div class="home-container">
            <div class="home-title">Descubra o diferencial exclusivo <br> da Aprojur e garanta sua aprovação!</div>
            <div style="width: 135px; height: 3px; background-color: #B99660; margin: 15px 0;"></div>
        </div>

        <div class="panel-navigation">
            <div class="panel-container" ref="panelContainer">
                <div class="panel-cards" style="margin-left: 10vw;"><img src="@/assets/diferencial/Card 01.png" width="100%"></div>
            </div>
            <button @click="scrollLeft" class="scroll-button scroll-button-left"><i class="pi pi-chevron-left" /></button>
            <button @click="scrollRight" class="scroll-button scroll-button-right"><i class="pi pi-chevron-right" /></button>
        </div>
        
    </div>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
            SCROLL_PX: 420
        };
    },
    methods: {
        scrollLeft() {
            this.$refs.panelContainer.scrollBy({
                left: -this.SCROLL_PX,
                behavior: 'smooth'
            });
        },
        scrollRight() {
            this.$refs.panelContainer.scrollBy({
                left: this.SCROLL_PX,
                behavior: 'smooth'
            });
        }
    }
};
</script>

<style scoped></style>