<template>
    <div style="display: flex; width: 100%; height: calc(100vh - 150px);">
        <template v-if="noData">
            <div
                style="width: 100%; background-color: #2E2F31; text-align: center; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 22px; color: white;">
                Temporariamente fora do ar, estamos trabalhando para resolver!
            </div>
        </template>
        <template v-else>
            <div
                style="width: 70%; background-color: #2E2F31; text-align: center; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <ProgressSpinner v-if="loadVideo" />
                <video v-if="videoUrl" style="border-radius: 10px; width: 80%;" :src="videoUrl" controls autoplay
                    @loadeddata="autoPlayVideo" />
                <div v-if="!videoUrl && !loadVideo" style="color: white; font-size: 19px; font-weight: bold;">Selecione um vídeo</div>
            </div>
            <div class="container-blocks">
                <ul style="color: white; padding-left: 0; cursor: pointer;">
                    <li v-for="(topic) in blocks" :key="topic.topic">
                        <div @click="toggleTopic(topic.topic)"
                            style="height: 80px; padding-top: 20px; font-size: 25px; padding-left: 20px; border-bottom: 1px solid #555555; border-top: 1px solid #555555">
                            <i v-if="!isTopicOpened(topic.topic)" class="pi pi-chevron-right pi-icon-dale" style="bottom: 3px;"/>
                            <i v-else class="pi pi-chevron-down pi-icon-dale" style="bottom: 3px;"/>
                            {{ topic.topic }}
                        </div>
                        <ul v-show="isTopicOpened(topic.topic)" style="padding-left: 0;">
                            <li v-for="(subtopic) in topic.subTopics" :key="subtopic.subtopic">
                                <div @click="toggleSubtopic(topic.topic, subtopic.subtopic)"
                                    style="height: 60px; padding-top: 17px; font-size: 18px; border-bottom: 1px solid #555555; background-color: #191919; padding-left: 40px;">
                                    <i v-if="!isSubtopicOpened(topic.topic, subtopic.subtopic)" class="pi pi-chevron-right pi-icon-dale"/>
                                    <i v-else class="pi pi-chevron-down pi-icon-dale"/>
                                    {{ subtopic.subtopic }}
                                </div>
                                <ul v-show="isSubtopicOpened(topic.topic, subtopic.subtopic)" style="padding-left: 0;">
                                    <li v-for="(module) in subtopic.modules" :key="module.module">
                                        <div @click="toggleModule(topic.topic, subtopic.subtopic, module.module)"
                                            style="height: 50px; padding-top: 12px; font-size: 18px; border-bottom: 1px solid #555555; background-color: #222222; padding-left: 60px;">
                                            <i v-if="!isModuleOpened(topic.topic, subtopic.subtopic, module.module)" class="pi pi-chevron-right pi-icon-dale"/>
                                            <i v-else class="pi pi-chevron-down pi-icon-dale"/>
                                            {{ module.module }}
                                        </div>
                                        <ul v-show="isModuleOpened(topic.topic, subtopic.subtopic, module.module)"
                                            style="padding-left: 0;">
                                            <li v-for="(video) in module.videos" :key="video.fileKey">
                                                <div @click="getVideo(topic.topic, subtopic.subtopic, module.module, video.fileKey)"
                                                    :class="{'selected' : video.fileKey === videoSelected}"
                                                    style="height: 50px; padding-top: 12px; font-size: 18px; border-bottom: 1px solid #555555; background-color: #252525; padding-left: 80px;">
                                                    {{ video.videoKey }}
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </template>
    </div>
</template>

<script>
import apiClient from '@/services/api';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    created() {
        if (this.$route.name === "inteligencia-emocional" || this.$route.name === "tecnicas-de-estudo") {
            this.noData = true
        } else {
            this.getList(this.$route.name.toString())
        }
    },
    data() {
        return {
            videoUrl: null,
            loadVideo: false,
            noData: false,
            blocks: [],
            openedTopics: {},
            openedSubtopics: {},
            openedModules: {},
            videoSelected: null
        };
    },
    methods: {
        toggleTopic(topicTitle) {
            this.$set(this.openedTopics, topicTitle, !this.openedTopics[topicTitle]);
        },
        toggleSubtopic(topicTitle, subtopicTitle) {
            if (!this.openedSubtopics[topicTitle]) {
                this.$set(this.openedSubtopics, topicTitle, {});
            }
            this.$set(this.openedSubtopics[topicTitle], subtopicTitle, !this.openedSubtopics[topicTitle][subtopicTitle]);
        },
        toggleModule(topicTitle, subtopicTitle, moduleTitle) {
            if (!this.openedModules[topicTitle]) {
                this.$set(this.openedModules, topicTitle, {});
            }
            if (!this.openedModules[topicTitle][subtopicTitle]) {
                this.$set(this.openedModules[topicTitle], subtopicTitle, {});
            }
            this.$set(this.openedModules[topicTitle][subtopicTitle], moduleTitle, !this.openedModules[topicTitle][subtopicTitle][moduleTitle]);
        },
        isTopicOpened(topicTitle) {
            return this.openedTopics[topicTitle];
        },
        isSubtopicOpened(topicTitle, subtopicTitle) {
            return this.openedSubtopics[topicTitle] && this.openedSubtopics[topicTitle][subtopicTitle];
        },
        isModuleOpened(topicTitle, subtopicTitle, moduleTitle) {
            return this.openedModules[topicTitle] && this.openedModules[topicTitle][subtopicTitle] && this.openedModules[topicTitle][subtopicTitle][moduleTitle];
        },
        sortTopics(topics) {
            return topics.map(topic => {
                topic.subTopics = topic.subTopics.map(subtopic => {
                    subtopic.modules = subtopic.modules.map(module => {
                        module.videos.sort((a, b) => a.videoKey.localeCompare(b.videoKey));
                        return module;
                    });
                    subtopic.modules.sort((a, b) => a.module.localeCompare(b.module));
                    return subtopic;
                });
                topic.subTopics.sort((a, b) => a.subtopic.localeCompare(b.subtopic));
                return topic;
            }).sort((a, b) => a.topic.localeCompare(b.topic));
        },
        async getList(route) {
            try {
                let response = await apiClient.get(`/file/list/${route}`);
                this.blocks = this.sortTopics(response.data)
                this.verifyRoute()
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao buscar PDF', life: 3000 });
            }
        },
        async getVideo(topic, subtopic, module, fileKey) {
            console.log(this.openedTopics);
            console.log(this.openedSubtopics);
            console.log(this.openedModules);
            this.videoUrl = null
            this.videoSelected = fileKey;
            let key = `Disciplinas/${topic}/${subtopic}/${module}/${fileKey}`
            console.log(key);
            this.loadVideo = true;
            try {
                let response = await apiClient.get(`/file/downloadVideo?key=${key}`, {
                    responseType: 'blob'
                });

                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'video/mp4' }));
                this.videoUrl = url;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar o vídeo', life: 3000 });
            } finally {
                this.loadVideo = false;
            }
        },
        autoPlayVideo(event) {
            let videoElement = event.target;
            videoElement.play();
        },
        verifyRoute(){
            let parts = this.$route.params.item.toString().split(" - ");
            let part1 = parts[0];
            let part2 = parts[1];

            let exists = Object.keys(this.openedTopics).some(key => 
                key === part1
            );

            this.openedTopics = {}
            if (!exists) {
                this.$set(this.openedTopics, part1, true);
            }

            this.openedSubtopics = {}
            if (!this.openedSubtopics[part1]) {
                this.$set(this.openedSubtopics, part1, {});
            }
            this.$set(this.openedSubtopics[part1], part2, true);
        }
    },
    watch: {
        '$route'(val) {
            if (val.name === "inteligencia-emocional" || val.name === "tecnicas-de-estudo") {
                this.noData = true
                return
            } else {
                this.noData = false
            }

            this.verifyRoute()
        },
    },
    components: {
        ProgressSpinner
    }
};
</script>

<style scoped>
.container-blocks {
    width: 30%;
    background-color: #161616;
    overflow: auto;
}

ul {
    list-style-type: none;
    padding-left: 20px;
}

.selected {
    background-color: #6b6b6b !important;
}

.pi-icon-dale{
    margin-right: 5px;
    font-size: 10px;
    position: relative;
    bottom: 2px;
}

</style>
