<template>
    <div style="background-color: #DFE6EC;">
        <Toast />

        <template v-if="loadingPayment">
            <div style="width: 100%; height: calc(100vh - 50px); align-items: center; justify-content: center; display: flex; flex-direction: column; text-align: center;">
                <ProgressSpinner />
            </div>
        </template>
        <template v-else>
            <div v-if="mode === 0" class="bloco-pai">
                <div class="primeiro-bloco">
                    <div class="dentro-primeiro-bloco" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                        <div class="home-title" style="font-size: 18px; margin: 20px 20px 5px 20px; color: #696969;">FORMA DE PAGAMENTO</div>
                        <TabView @tab-click="changeHeader($event.index)" class="tabview-custom">
                            <TabPanel>
                                <template #header>
                                    <div @click="clickOutPix" style="display: flex; flex-wrap: nowrap">
                                        <span style="font-family: 'Montserrat Regular 400'; font-size: 14px;">Cartão de crédito</span>
                                        <i class="pi pi-credit-card" style="font-size: 20px; margin-left: 20px;" />
                                    </div>
                                </template>
                                <div style="font-family: 'Montserrat Regular 400'; font-size: 14px;">
                                    <div style="display: flex;">
                                        <div class="form-group" style="width: calc(70% - 5px);">
                                            <label for="cardNumber">Número do Cartão</label>
                                            <InputMask mask="9999 9999 9999 9999" id="cardNumber" v-model="form.cardNumber" />
                                        </div>
                                        <div class="form-group" style="width: calc(30% - 5px); margin-left: 10px;">
                                            <label for="cardFlag">Bandeira</label>
                                            <InputText id="cardFlag" v-model="form.cardFlag" />
                                        </div>
                                    </div>
                                    <div style="display: flex;">
                                        <div class="form-group" style="width: calc(50% - 5px);">
                                            <label for="expiryMonth">Mês de Vencimento</label>
                                            <Dropdown class="w-100" v-model="form.expiryMonth" :options="expiryMonthOptions" optionLabel="name" placeholder="Selecione o mês" />
                                        </div>
                                        <div class="form-group" style="width: calc(50% - 5px); margin-left: 10px;">
                                            <label for="expiryYear">Ano de Vencimento</label>
                                            <Dropdown class="w-100" v-model="form.expiryYear" :options="expiryYearOptions" optionLabel="name" placeholder="Selecione o ano" />
                                        </div>
                                    </div>
                                    <div style="display: flex;">
                                        <div class="form-group" style="width: calc(50% - 5px);">
                                            <label for="securityCode">Código de Segurança</label>
                                            <InputText id="securityCode" v-model="form.securityCode" />
                                        </div>
                                        <div class="form-group" style="width: calc(50% - 5px); margin-left: 10px;">
                                            <label for="installmentOptions">Opções de Parcelamento</label>
                                            <Dropdown class="w-100" v-model="form.installmentOptions" :options="installmentOptions" optionLabel="name" placeholder="Selecione a opção de parcelamento" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <template #header>
                                    <div @click="clickPix" style="display: flex; flex-wrap: nowrap">
                                        <span style="font-family: 'Montserrat Regular 400';font-size: 14px;">Pix</span>
                                        <img src="@/assets/pix.svg" style="width: 20px; height: 20px; margin-left: 15px;">
                                    </div>
                                </template>
                                <div style="padding: 20px; text-align: center; font-family: 'Montserrat Regular 400'; font-size: 14px;">
                                    <img src="@/assets/pix bix.png" style="width: 200px;">
                                    <div style="display: flex;">
                                        <div style="width: 30%; display: flex; flex-direction: column; align-items: center; text-align: center;">
                                            <div style="height: 40px; width: 40px; border: 1px solid #77B7A8; text-align: center; display: flex; flex-direction: column; justify-content: center; border-radius: 20px; margin-bottom: 10px;">1</div>
                                            <div>Clique em finalizar compra para gerar o código QR</div>
                                        </div>
                                        <div style="width: 40%; display: flex; flex-direction: column; justify-content: center;">
                                            <div style="height: 1px; width: 80%; margin: 0 auto; background-color: #bbbbbb;"></div>
                                        </div>
                                        <div style="width: 30%; display: flex; flex-direction: column; align-items: center; text-align: center;">
                                            <div style="height: 40px; width: 40px; border: 1px solid #77B7A8; text-align: center; display: flex; flex-direction: column; justify-content: center; border-radius: 20px; margin-bottom: 10px;">2</div>
                                            <div>Confira os dados e realize o pagamento pelo app do seu banco</div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                        <div style="width: 100%; height: 20px; background-color: #F0F2F2; position: relative; top: calc(100% - 20px); border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: 1px solid #aaaaaa;"></div>
                    </div>
                </div>
                
                <div class="segundo-bloco" style="font-family: 'Montserrat Regular 400'; font-size: 14px;">
                    <div class="dentro-segundo-bloco" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                        <div style="padding: 20px; height: calc(100% - 20px);">
                            <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
                                <div class="home-title" style="font-size: 18px; color: #696969;">ENDEREÇO</div>
                                <div class="button-style-edit" @click="openDialogEditAddress">Editar</div>
                            </div>
                            <div style="font-size: 14px; color: gray;" v-if="responseAddress != null">{{ responseAddress.rua }} - {{ responseAddress.num }} - {{ responseAddress.complemento }}</div>
                            <div style="font-size: 14px; color: gray;" v-if="responseAddress != null">{{ responseAddress.bairro }} - {{ responseAddress.cidade }} - {{ responseAddress.estado }}</div>
                            <div style="font-size: 14px; color: gray;" v-if="responseAddress != null">{{ responseAddress.cep }}</div>
                        </div>
                        <div style="width: 100%; height: 20px; background-color: #F0F2F2; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: 1px solid #aaaaaa;"></div>
                    </div>
                    <div class="dentro-segundo-proximo-bloco" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                        <div style="padding: 20px; height: calc(100% - 60px);">
                            <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
                                <div class="home-title" style="font-size: 18px; color: #696969;">PEDIDO</div>
                                <div class="button-style-edit" @click="$router.push('/plans');">Trocar</div>
                            </div>
                            <div style="font-size: 14px; color: gray;">
                                {{ items.productId === '1' ? "1ª Fase - ESSENCIAL" : items.productId === '2' ? "1ª Fase - PREMIUM" : "1ª + 2ª Fase - PREMIUM" }}
                            </div>
                            <div style="font-size: 14px; color: gray; margin-top: 5px;" v-if="!isPix">{{ form.installmentOptions.name }}</div>
                            <div style="font-size: 14px; color: gray; margin-top: 5px;" v-else>{{ items.productId === '1' ? "R$560,50" : items.productId === '2' ? "R$560,50" : "R$1470,60" }}</div>
                        </div>
                        <div style="width: 100%; height: 60px; background-color: #F0F2F2; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; border-top: 1px solid #aaaaaa;">
                            <div class="button-style" style="width: 70%; padding: 0; margin: 10px auto;" @click="finishPayment()">Finalizar Compra</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="mode === 1" style="display: flex; width: 100%; margin: 0 auto;">
                <div style="width: 100%; padding: 20%; height: auto; margin-top: 50px; justify-content: center; padding-top: 30px;">
                    <p class="m-0">
                        Pagamento efetuado com sucesso
                    </p>
                </div>
            </div>
            <div v-else style="width: 100%; padding: 0 10%; min-height: calc(100vh - 50px);">
                <div style="margin-top: 30px;">
                    <div class="home-title" style="font-size: 18px; color: #696969;">Efetue o Pagamento</div>
                    <br>
                    <div v-if="infoPix" style="width: 100%; max-width: 400px;">
                        <label for="pixCode">Código PIX:</label>
                        <InputText id="pixCode" v-model="infoPix.qrcode" disabled />
                        <div class="button-style" @click="copyPixCode" label="Copiar Código">Copiar código</div>
                        <div style="margin-top: 30px;">
                            <label for="qrcode">QR Code:</label>
                            <img :src="infoPix.imagemQrcode" alt="QR Code PIX" id="qrcode">
                        </div>
                    </div>
                </div>
            </div>
        </template>


        <b-modal v-model="dialogEditAddress" size="lg" centered class="custom-dialog">
            <template v-slot:modal-title>
                <h5>Editar Endereço</h5>
            </template>

            <!-- CEP -->
            <div class="item-style">
                <span>CEP *</span><br>
                <InputMask class="w-100" mask="99999-999" v-model="formEditAddress.cep" />
            </div>
            
            <div class="button-style" @click="consultarCEP">Consultar CEP</div>
            
            <div style="display: flex; margin-top: 15px;">
                <!-- Estado -->
                <div class="item-style" style="width: calc(50% - 5px);">
                    <span>Estado *</span><br>
                    <InputMask mask="aa" class="w-100" v-model="formEditAddress.estado" />
                </div>
                
                <!-- Cidade -->
                <div class="item-style" style="width: calc(50% - 5px); margin-left: 10px;">
                    <span>Cidade *</span><br>
                    <InputText class="w-100" v-model="formEditAddress.cidade" />
                </div>
            </div>
            
            <div style="display: flex; margin-top: 15px;">
                <!-- Rua -->
                <div class="item-style" style="width: calc(50% - 5px);">
                    <span>Endereço *</span><br>
                    <InputText class="w-100" v-model="formEditAddress.rua" />
                </div>
                
                
                <!-- Número -->
                <div class="item-style" style="width: calc(50% - 5px); margin-left: 10px;">
                    <span>Número *</span><br>
                    <InputText class="w-100" v-model="formEditAddress.num" />
                </div>
            </div>

            <div style="display: flex; margin-top: 15px;">
                <!-- BAIRRO -->
                <div class="item-style" style="width: calc(50% - 5px);">
                    <span>Bairro/Localidade *</span><br>
                    <InputText class="w-100" v-model="formEditAddress.bairro" />
                </div>

                <!-- COMPLEMENTO -->
                <div class="item-style" style="width: calc(50% - 5px); margin-left: 10px;">
                    <span>Complemento *</span><br>
                    <InputText class="w-100" v-model="formEditAddress.complemento" />
                </div>
            </div>
            
            <template v-slot:modal-footer>
                <b-button variant="secondary" @click="saveDialogEditAddress">Salvar</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import apiClient from '@/services/api';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import InputMask from 'primevue/inputmask';
import Toast from 'primevue/toast/Toast';
import ProgressSpinner from 'primevue/progressspinner';
/* global EfiJs */

export default {
    created() {
        if (!localStorage.getItem('userId')) {
            this.$router.push('/login');
            return
        } else if(!localStorage.getItem('product')){
            this.$router.push('/plans');
            return
        }
        
        this.installmentOptions = this.items.productId === '3' ?
            [
                {"name": "1x de R$1.548,00 sem juros", "value": 154800, "installment": 1},
                {"name": "2x de R$774,00 sem juros", "value": 77400, "installment": 2},
                {"name": "3x de R$516,00 sem juros", "value": 51600, "installment": 3},
                {"name": "4x de R$387,00 sem juros", "value": 38700, "installment": 4},
                {"name": "5x de R$309,60 sem juros", "value": 30960, "installment": 5},
                {"name": "6x de R$257,00 sem juros", "value": 25700, "installment": 6},
                {"name": "7x de R$221,14 sem juros", "value": 22114, "installment": 7},
                {"name": "8x de R$193,50 sem juros", "value": 19350, "installment": 8},
                {"name": "9x de R$171,00 sem juros", "value": 17100, "installment": 9},
                {"name": "10x de R$154,80 sem juros", "value": 15480, "installment": 10},
                {"name": "11x de R$140,73 sem juros", "value": 14073, "installment": 11},
                {"name": "12x de R$129,00 sem juros", "value": 12900, "installment": 12}
            ]
            :
            [
                {"name": "1x de R$590,00 sem juros", "value": 59000, "installment": 1},
                {"name": "2x de R$295,00 sem juros", "value": 29500, "installment": 2},
                {"name": "3x de R$196,67 sem juros", "value": 19667, "installment": 3},
                {"name": "4x de R$147,50 sem juros", "value": 14750, "installment": 4},
                {"name": "5x de R$118,00 sem juros", "value": 11800, "installment": 5},
                {"name": "6x de R$98,33 sem juros", "value": 9833, "installment": 6},
                {"name": "7x de R$84,29 sem juros", "value": 8429, "installment": 7},
                {"name": "8x de R$73,75 sem juros", "value": 7375, "installment": 8},
                {"name": "9x de R$65,56 sem juros", "value": 6556, "installment": 9},
                {"name": "10x de R$59,00 sem juros", "value": 5900, "installment": 10}
            ]

        this.getAddress()
    },
    components: {
        InputText, Dropdown, TabPanel, TabView, InputMask, Toast, ProgressSpinner
    },
    data() {
        return {
            items: {
                userId: localStorage.getItem('userId'),
                productId: localStorage.getItem('product')
            },
            form: {
                cardNumber: '',
                cardFlag: '',
                expiryMonth: '',
                expiryYear: '',
                securityCode: '',
                installmentOptions: ''
            },
            expiryYearOptions: [
                {name: '2023', value: '2023'},
                {name: '2024', value: '2024'},
                {name: '2025', value: '2025'},
                {name: '2026', value: '2026'},
                {name: '2027', value: '2027'},
                {name: '2028', value: '2028'},
                {name: '2029', value: '2029'},
                {name: '2030', value: '2030'},
                {name: '2031', value: '2031'},
                {name: '2032', value: '2032'},
                {name: '2033', value: '2033'},
                {name: '2034', value: '2034'},
                {name: '2035', value: '2035'},
            ],
            expiryMonthOptions: [
                {name: '01', value: '01'},
                {name: '02', value: '02'},
                {name: '03', value: '03'},
                {name: '04', value: '04'},
                {name: '05', value: '05'},
                {name: '06', value: '06'},
                {name: '07', value: '07'},
                {name: '08', value: '08'},
                {name: '09', value: '09'},
                {name: '10', value: '10'},
                {name: '11', value: '11'},
                {name: '12', value: '12'}
            ],
            infoPix: null,
            tabIndex: 0,
            dialogEditAddress: false,
            formEditAddress: {
                estado: '',
                cidade: '',
                rua: '',
                num: '',
                cep: '',
                bairro: '',
                complemento: ''
            },
            responseAddress: null,
            mode: 0,
            isPix: false,
            loadingPayment: false
        };
    },
    watch: {
        'form.cardNumber': function (val) {
            if(val === ''){
                this.form.cardFlag = ''
                return
            }

            EfiJs.CreditCard
            .setCardNumber(val)
            .verifyCardBrand()
            .then(brand => {
                if (brand !== 'undefined') {
                    this.form.cardFlag = brand
                }
            })
        }
    },
    methods: {
        clickPix(){
            this.isPix = true
        },
        clickOutPix(){
            this.isPix = false
        },
        changeHeader(val){
            this.tabIndex = val
        },
        async getAddress(){
            try {
                const response = await apiClient.get(`/auth/address/${this.items.userId}`);
                response.data.cep = response.data.cep.slice(0, 5) + '-' + response.data.cep.slice(5)
                this.responseAddress = response.data

                this.formEditAddress.cep = this.responseAddress.cep;
                this.formEditAddress.num = this.responseAddress.num;
                this.formEditAddress.rua = this.responseAddress.rua;
                this.formEditAddress.bairro = this.responseAddress.bairro;
                this.formEditAddress.cidade = this.responseAddress.cidade;
                this.formEditAddress.estado = this.responseAddress.estado;
                this.formEditAddress.complemento = this.responseAddress.complemento;
                this.form.installmentOptions = this.installmentOptions[0];

            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        },
        getPaymentToken(){
            if(this.form.cardFlag === '' ||
                    this.form.cardNumber === '' ||
                    this.form.securityCode === '' ||
                    this.form.expiryMonth.value === '' ||
                    this.form.expiryYear.value === ''){
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Preencha todos os campos', life: 3000 });
                return
            }

            EfiJs.CreditCard
                .setAccount('35fa708c551169b5448ef19282c9014a')
                .setEnvironment('production')
                .setCreditCardData({
                    brand: this.form.cardFlag,
                    number: this.form.cardNumber,
                    cvv: this.form.securityCode,
                    expirationMonth: this.form.expiryMonth.value,
                    expirationYear: this.form.expiryYear.value,
                    reuse: false
                })
                .getPaymentToken()
                .then(data => {
                    this.sendPayment(data.payment_token);
                })
        },
        async getPix(){
            this.loadingPayment = true
            try {
                const response = await apiClient.post('/create/pix', this.items);
                this.infoPix = response.data;
                this.mode = 2
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível gerar o QR Code, tente novamente mais tarde!', life: 3000 });
            } finally{
                this.loadingPayment = false
            }
        },
        async sendPayment(val){
            this.loadingPayment = true
            try {
                let params = {
                    userId: this.items.userId,
                    productId: this.items.productId,
                    installments: this.form.installmentOptions.installment,
                    payment_token: val,
                    estado: this.formEditAddress.estado,
                    cidade: this.formEditAddress.cidade,
                    rua: this.formEditAddress.rua,
                    num: this.formEditAddress.num,
                    cep: this.formEditAddress.cep,
                    bairro: this.formEditAddress.bairro,
                    complemento: this.formEditAddress.complemento
                }
                const response = await apiClient.post('/creditCard', params);
                localStorage.setItem('token', response.data.token)
                this.$router.push('/homeCourse');
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível gerar a cobrança, tente novamente mais tarde!', life: 3000 });
            } finally{
                this.loadingPayment = false
            }
        },
        copyPixCode() {
            let input = document.createElement('input');
            input.value = this.infoPix.qrcode;
            document.body.appendChild(input);
            input.select();

            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('Erro ao copiar o texto: ', err);
            }

            document.body.removeChild(input);
        },
        finishPayment() {
            this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Funcionalidade desativada por tempo indeterminado', life: 3000 });

            // if(this.tabIndex === 0){
            //     this.getPaymentToken()
            //     return
            // }

            // this.getPix()
        },
        async consultarCEP() {
            const cep = this.formEditAddress.cep.replace(/\D/g, '');
            try {
                const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
                const data = await response.json();
                
                if (data.erro) {
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível encontrar o CEP', life: 3000 });
                    this.formEditAddress.rua = '';
                    this.formEditAddress.bairro = '';
                    this.formEditAddress.cidade = '';
                    this.formEditAddress.estado = '';
                    this.errorMessage = '';
                } else {
                    this.formEditAddress.rua = data.logradouro;
                    this.formEditAddress.bairro = data.bairro;
                    this.formEditAddress.cidade = data.localidade;
                    this.formEditAddress.estado = data.uf;
                    this.errorMessage = '';
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível encontrar o CEP', life: 3000 });
                this.formEditAddress.rua = '';
                this.formEditAddress.bairro = '';
                this.formEditAddress.cidade = '';
                this.formEditAddress.estado = '';
            }
        },
        openDialogEditAddress(){
            this.dialogEditAddress = true
        },
        saveDialogEditAddress(){
            this.responseAddress.cep = this.formEditAddress.cep;
            this.responseAddress.num = this.formEditAddress.num;
            this.responseAddress.rua = this.formEditAddress.rua;
            this.responseAddress.bairro = this.formEditAddress.bairro;
            this.responseAddress.cidade = this.formEditAddress.cidade;
            this.responseAddress.estado = this.formEditAddress.estado;
            this.responseAddress.complemento = this.formEditAddress.complemento;
            this.dialogEditAddress = false
        }
    }
};
</script>

<style scoped>
.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"] {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.button-style{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 150px;
    color: #00A3B4;
    background-color: white;
    margin: 25px auto 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}

.button-style-edit{
    height: 35px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 80px;
    color: #00A3B4;
    background-color: white;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
}

.button-style-edit:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}

.primeiro-bloco {
    width: 65%;
    height: auto;
    margin: 25px 0;
    border-right: 1px solid #888888;
    justify-content: center;
    padding: 30px 0;
}


.segundo-bloco {
    width: 35%;
    height: 550px;
    margin: 25px 0;
    justify-content: center;
    padding: 30px 0;
}

.bloco-pai{
    display: flex; 
    padding: 0 10%;
}

.dentro-primeiro-bloco{
    width: calc(100% - 30px);
    border: 1px solid #aaaaaa;
    border-radius: 10px;
    background-color: white;
    height: auto;
}

.dentro-segundo-bloco {
    width: calc(100% - 30px);
    float: right;
    border: 1px solid #aaaaaa;
    background-color: white;
    border-radius: 10px;
    height: auto;
}

.dentro-segundo-proximo-bloco {
    width: calc(100% - 30px);
    float: right;
    border: 1px solid #aaaaaa;
    background-color: white;
    border-radius: 10px;
    height: auto;
    margin-top: 30px;
}

@media (max-width: 1024px) {

    .primeiro-bloco {
        width: 100%;
        margin: 0;
        border-right: none;
        padding: 30px 0;
    }

    .segundo-bloco {
        width: 100%;
        height: 450px;
        margin: 0;
        padding: 0;
    }

    .bloco-pai{
        display: block;
    }

    .dentro-primeiro-bloco{
        width: 100%;
    }

    .dentro-segundo-bloco {
        width: 100%;
    }

    .dentro-segundo-proximo-bloco {
        width: 100%;
        float: right;
        border: 1px solid #aaaaaa;
        border-radius: 10px;
        height: auto;
        margin-top: 30px;
    }
}

</style>

<style>

.p-tabview-nav-content{
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: #2D4678 !important;
    color: #2D4678 !important;
}

</style>