<template>
    <div style="background-color: #F5F5F7;">
        <div style="padding: 35px 10%; display: flex;" v-if="screenWidth > 1024">
            <div style="width: calc(100% - 340px);">
                <div v-if="plan === '1'" class="plans-title">
                    Curso <span style="color: #B3925E;">ESSENCIAL</span>
                </div>
                <div v-if="plan === '2'" class="plans-title" style="font-family: 'Montserrat Regular 400'">
                    <span style="font-family: 'Montserrat Black 900', sans-serif;">Curso <span
                            style="color: #B3925E;">PREMIUM</span></span> <span
                        style="font-size: 19px; font-weight: normal;">Online</span>
                </div>
                <div v-if="plan === '3'" class="plans-title">
                    Curso Premium <span style="color: #B3925E;">online</span>
                </div>
                <div v-if="plan === '1'" style="font-family: 'Montserrat Regular 400'">
                    <span style="font-weight: bold;">1ª FASE OAB</span> EXAME 42
                </div>
                <div v-if="plan === '2'" style="font-family: 'Montserrat Regular 400'">
                    Confira nossos diferenciais do <span style="font-weight: bold;">Curso de 1ª fase da OAB:</span>
                </div>
                <div v-if="plan === '3'" style="font-family: 'Montserrat Regular 400'">
                    <span style="font-weight: bold;">Combo:</span> 1ª + 2ª Fase - OAB - EXAME 42
                </div>

                <div v-if="plan === '1'"
                    style="height: 550px; width: 95%; padding: 20px; background-color: white; overflow: auto; border-radius: 15px; margin-top: 25px;">
                    <div style="font-size: 18px; font-weight: bold;">Aulas Gravadas:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Conteúdo
                        completo e
                        aprofundado de todas as disciplinas da 1ª Fase da OAB, organizado em vídeo de 15 a 20 minutos de
                        duração, aulas em
                        alta qualidade para você assistir no seu ritmo.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Resoluções
                        de questões
                        pelo professor ao final de cada módulo, para fixação de conteúdo.
                    </div>

                    <!-- <div style="font-size: 18px; font-weight: bold;">Mentoria AO VIVO:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Tire
                        suas dúvidas
                        diretamente com nossos professores durante a mentoria ao vivo que acontece de forma online.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Um
                        espaço para tirar
                        dúvidas, aprofundar seus conhecimentos e receber orientação personalizada para seus estudos.
                    </div> -->

                    <div style="font-size: 18px; font-weight: bold;">Análise de Desempenho:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Monitore
                        seu progresso
                        individualmente com relatórios detalhados de desempenho.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Identifique
                        seus
                        pontos fortes e fracos para direcionar seus estudos de forma eficaz.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />A
                        Devolutiva de
                        Desempenho combina análise detalhada de resultados com mentoria ao vivo e estratégias definidas
                        para ajudá-lo a
                        alcançar seus objetivos de forma eficaz.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Estude por Blocos:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Organize
                        seus estudos
                        de maneira eficiente com a divisão do conteúdo em blocos temáticos.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Concentre-se
                        em um
                        módulo por vez para um aprendizado mais aprofundado e eficiente.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Materiais Esquematizados para Leitura:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />PDFs
                        completos com
                        resumos, mapas mentais e outros materiais de apoio para facilitar seus estudos.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Conteúdo
                        prático e
                        organizado para otimizar seu tempo e te ajudar a revisar os principais pontos de cada
                        disciplina.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Aulas Dinâmicas:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Participe
                        de aulas
                        interativas e envolventes com nossos professores experientes.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        de forma
                        dinâmica e divertida, com recursos audiovisuais e atividades que tornam o aprendizado mais leve
                        e eficaz.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Professores Didáticos:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Conte
                        com o know-how
                        de uma equipe de professores altamente qualificados e com vasta experiência em preparar alunos
                        para o Exame de
                        Ordem.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Profissionais
                        dedicados que te guiarão em cada etapa da sua jornada de estudos.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Metodologia de Estudo:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Desenvolva
                        as melhores
                        técnicas de estudo para otimizar seu tempo e potencializar seus resultados.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        como organizar
                        seus estudos, fazer anotações eficientes e revisar o conteúdo de forma eficaz.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Inteligência Emocional:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Mantenha
                        o foco e a
                        tranquilidade durante os estudos e no dia da prova com técnicas de inteligência emocional.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        a lidar com o
                        estresse e a ansiedade para ter um desempenho máximo no exame.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Simulado Personalizável:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Pratique
                        para o Exame
                        de Ordem com simulados realistas e personalizáveis.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Crie
                        simulados com as
                        disciplinas e questões que você precisa focar para se preparar da melhor forma.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Crie Seu Cronograma de Estudo:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Crie
                        um cronograma de
                        estudos personalizado de acordo com suas necessidades e disponibilidade.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Organize
                        seus estudos
                        de forma eficiente para alcançar seus objetivos.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Técnicas de Resolução de Questões:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Desvende
                        as melhores
                        técnicas para resolver as questões da OAB na 1ª Fase com rapidez e precisão.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        a analisar as
                        questões, identificar os pontos-chave e chegar à resposta correta.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Resolução de questão pelo professor ao final de
                        cada módulo:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Reforce
                        seu
                        aprendizado com a resolução de questões comentadas durante a ministração das aulas.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Maximize o Potencial do Seu Vade Mecum:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Desvende
                        os segredos
                        para utilizar seu Vade Mecum como um aliado estratégico nas provas.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        técnicas
                        avançadas de consulta e manuseio que te farão encontrar as informações necessárias em tempo
                        recorde.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Maximize
                        sua
                        produtividade e garanta respostas precisas e completas.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Marcação e Organização Eficiente:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Receba
                        orientações
                        detalhadas sobre como destacar e organizar seu Vade Mecum de forma eficaz.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Nossas
                        aulas ensinarão
                        técnicas de marcação que facilitarão a localização ágil das informações essenciais durante o
                        exame.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Correção e Aperfeiçoamento:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Receba
                        uma avaliação
                        personalizada de suas peças, com dicas práticas para aprimorar sua escrita jurídica e otimizar
                        seu tempo nas provas.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Beneficie-se
                        de uma
                        análise adaptada às suas necessidades, focada no desenvolvimento das suas habilidades na
                        elaboração de peças
                        processuais e na eficácia ao resolver questões práticas.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Seguro Aprojur - 2ª Fase:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Se
                        o candidato não for
                        aprovado para a 2ª fase, isto é, se for reprovado, o seguro oferece um crédito de R$ 958,00
                        (novecentos e cinquenta
                        e oito reais) na Aprojur, correspondente à 2ª fase.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />O
                        crédito não possui
                        prazo de validade e pode ser utilizado a qualquer momento, podendo ser aplicado na aquisição de
                        qualquer produto
                        disponível na Aprojur.
                    </div>
                </div>

                <div v-if="plan === '2'"
                    style="height: 550px; width: 95%; padding: 20px; background-color: white; overflow: auto; border-radius: 15px; margin-top: 25px;">
                    <div style="font-size: 18px; font-weight: bold;">Aulas Gravadas:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Conteúdo
                        completo e
                        aprofundado de todas as disciplinas da 1ª Fase da OAB, organizado em vídeo de 15 a 20 minutos de
                        duração, aulas em
                        alta qualidade para você assistir no seu ritmo.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Resoluções
                        de questões
                        pelo professor ao final de cada módulo, para fixação de conteúdo.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Mentoria AO VIVO:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Tire
                        suas dúvidas
                        diretamente com nossos professores durante a mentoria ao vivo que acontece de forma online.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Um
                        espaço para tirar
                        dúvidas, aprofundar seus conhecimentos e receber orientação personalizada para seus estudos.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Análise de Desempenho:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Monitore
                        seu progresso
                        individualmente com relatórios detalhados de desempenho.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Identifique
                        seus
                        pontos fortes e fracos para direcionar seus estudos de forma eficaz.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />A
                        Devolutiva de
                        Desempenho combina análise detalhada de resultados com mentoria ao vivo e estratégias definidas
                        para ajudá-lo a
                        alcançar seus objetivos de forma eficaz.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Estude por Blocos:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Organize
                        seus estudos
                        de maneira eficiente com a divisão do conteúdo em blocos temáticos.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Concentre-se
                        em um
                        módulo por vez para um aprendizado mais aprofundado e eficiente.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Materiais Esquematizados para Leitura:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />PDFs
                        completos com
                        resumos, mapas mentais e outros materiais de apoio para facilitar seus estudos.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Conteúdo
                        prático e
                        organizado para otimizar seu tempo e te ajudar a revisar os principais pontos de cada
                        disciplina.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Aulas Dinâmicas:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Participe
                        de aulas
                        interativas e envolventes com nossos professores experientes.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        de forma
                        dinâmica e divertida, com recursos audiovisuais e atividades que tornam o aprendizado mais leve
                        e eficaz.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Professores Didáticos:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Conte
                        com o know-how
                        de uma equipe de professores altamente qualificados e com vasta experiência em preparar alunos
                        para o Exame de
                        Ordem.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Profissionais
                        dedicados que te guiarão em cada etapa da sua jornada de estudos.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Metodologia de Estudo:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Desenvolva
                        as melhores
                        técnicas de estudo para otimizar seu tempo e potencializar seus resultados.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        como organizar
                        seus estudos, fazer anotações eficientes e revisar o conteúdo de forma eficaz.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Inteligência Emocional:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Mantenha
                        o foco e a
                        tranquilidade durante os estudos e no dia da prova com técnicas de inteligência emocional.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        a lidar com o
                        estresse e a ansiedade para ter um desempenho máximo no exame.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Simulado Personalizável:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Pratique
                        para o Exame
                        de Ordem com simulados realistas e personalizáveis.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Crie
                        simulados com as
                        disciplinas e questões que você precisa focar para se preparar da melhor forma.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Crie Seu Cronograma de Estudo:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Crie
                        um cronograma de
                        estudos personalizado de acordo com suas necessidades e disponibilidade.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Organize
                        seus estudos
                        de forma eficiente para alcançar seus objetivos.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Técnicas de Resolução de Questões:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Desvende
                        as melhores
                        técnicas para resolver as questões da OAB na 1ª Fase com rapidez e precisão.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        a analisar as
                        questões, identificar os pontos-chave e chegar à resposta correta.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Resolução de questão pelo professor ao final de
                        cada módulo:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Reforce
                        seu
                        aprendizado com a resolução de questões comentadas durante a ministração das aulas.
                    </div>
                </div>

                <div v-if="plan === '3'"
                    style="height: 550px; width: 95%; padding: 20px; background-color: white; overflow: auto; border-radius: 15px; margin-top: 25px;">
                    <div style="font-size: 18px; font-weight: bold;">Aulas Gravadas:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Conteúdo
                        completo e
                        aprofundado de todas as disciplinas da 1ª Fase da OAB, organizado em vídeo de 15 a 20 minutos de
                        duração, aulas em
                        alta qualidade para você assistir no seu ritmo.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Resoluções
                        de questões
                        pelo professor ao final de cada módulo, para fixação de conteúdo.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Mentoria AO VIVO:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Tire
                        suas dúvidas
                        diretamente com nossos professores durante a mentoria ao vivo que acontece de forma online.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Um
                        espaço para tirar
                        dúvidas, aprofundar seus conhecimentos e receber orientação personalizada para seus estudos.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Análise de Desempenho:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Monitore
                        seu progresso
                        individualmente com relatórios detalhados de desempenho.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Identifique
                        seus
                        pontos fortes e fracos para direcionar seus estudos de forma eficaz.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />A
                        Devolutiva de
                        Desempenho combina análise detalhada de resultados com mentoria ao vivo e estratégias definidas
                        para ajudá-lo a
                        alcançar seus objetivos de forma eficaz.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Estude por Blocos:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Organize
                        seus estudos
                        de maneira eficiente com a divisão do conteúdo em blocos temáticos.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Concentre-se
                        em um
                        módulo por vez para um aprendizado mais aprofundado e eficiente.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Materiais Esquematizados para Leitura:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />PDFs
                        completos com
                        resumos, mapas mentais e outros materiais de apoio para facilitar seus estudos.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Conteúdo
                        prático e
                        organizado para otimizar seu tempo e te ajudar a revisar os principais pontos de cada
                        disciplina.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Aulas Dinâmicas:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Participe
                        de aulas
                        interativas e envolventes com nossos professores experientes.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        de forma
                        dinâmica e divertida, com recursos audiovisuais e atividades que tornam o aprendizado mais leve
                        e eficaz.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Professores Didáticos:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Conte
                        com o know-how
                        de uma equipe de professores altamente qualificados e com vasta experiência em preparar alunos
                        para o Exame de
                        Ordem.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Profissionais
                        dedicados que te guiarão em cada etapa da sua jornada de estudos.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Metodologia de Estudo:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Desenvolva
                        as melhores
                        técnicas de estudo para otimizar seu tempo e potencializar seus resultados.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        como organizar
                        seus estudos, fazer anotações eficientes e revisar o conteúdo de forma eficaz.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Inteligência Emocional:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Mantenha
                        o foco e a
                        tranquilidade durante os estudos e no dia da prova com técnicas de inteligência emocional.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        a lidar com o
                        estresse e a ansiedade para ter um desempenho máximo no exame.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Simulado Personalizável:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Pratique
                        para o Exame
                        de Ordem com simulados realistas e personalizáveis.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Crie
                        simulados com as
                        disciplinas e questões que você precisa focar para se preparar da melhor forma.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Crie Seu Cronograma de Estudo:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Crie
                        um cronograma de
                        estudos personalizado de acordo com suas necessidades e disponibilidade.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Organize
                        seus estudos
                        de forma eficiente para alcançar seus objetivos.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Técnicas de Resolução de Questões:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Desvende
                        as melhores
                        técnicas para resolver as questões da OAB na 1ª Fase com rapidez e precisão.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        a analisar as
                        questões, identificar os pontos-chave e chegar à resposta correta.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Resolução de questão pelo professor ao final de
                        cada módulo:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Reforce
                        seu
                        aprendizado com a resolução de questões comentadas durante a ministração das aulas.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Maximize o Potencial do Seu Vade Mecum:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Desvende
                        os segredos
                        para utilizar seu Vade Mecum como um aliado estratégico nas provas.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Aprenda
                        técnicas
                        avançadas de consulta e manuseio que te farão encontrar as informações necessárias em tempo
                        recorde.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Maximize
                        sua
                        produtividade e garanta respostas precisas e completas.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Marcação e Organização Eficiente:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Receba
                        orientações
                        detalhadas sobre como destacar e organizar seu Vade Mecum de forma eficaz.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Nossas
                        aulas ensinarão
                        técnicas de marcação que facilitarão a localização ágil das informações essenciais durante o
                        exame.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Correção e Aperfeiçoamento:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Receba
                        uma avaliação
                        personalizada de suas peças, com dicas práticas para aprimorar sua escrita jurídica e otimizar
                        seu tempo nas provas.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Beneficie-se
                        de uma
                        análise adaptada às suas necessidades, focada no desenvolvimento das suas habilidades na
                        elaboração de peças
                        processuais e na eficácia ao resolver questões práticas.
                    </div>

                    <div style="font-size: 18px; font-weight: bold;">Seguro Aprojur - 2ª Fase:</div>
                    <div style="width: 100%; height: 2px; background-color: #B3925E; margin: 10px 0;"></div>

                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />Se
                        o candidato não for
                        aprovado para a 2ª fase, isto é, se for reprovado, o seguro oferece um crédito de R$ 958,00
                        (novecentos e cinquenta
                        e oito reais) na Aprojur, correspondente à 2ª fase.
                    </div>
                    <div style="margin: 10px 0 15px 0;">
                        <i class="pi pi-circle-fill"
                            style="color: #B3925E; font-size: 8px; margin: 0 5px; position: relative; bottom: 2px;" />O
                        crédito não possui
                        prazo de validade e pode ser utilizado a qualquer momento, podendo ser aplicado na aquisição de
                        qualquer produto
                        disponível na Aprojur.
                    </div>
                </div>
            </div>
            <div style="width: 340px;">
                <div class="panel-cards b-shadow" style="width: 100%; cursor: pointer;" @click="selectProduct"><img
                        :src="getImageUrl(this.plan)" width="100%"></div>
            </div>
        </div>
        <div v-else>
            <div v-if="plan === '1'">
                <img src="@/assets/planos-details/Card 01 responsivo.png" width="100%">
            </div>
            <div v-if="plan === '2'">
                <img src="@/assets/planos-details/Card 02 responsivo.png" width="100%">
            </div>
            <div v-if="plan === '3'">
                <img src="@/assets/planos-details/Card 03 responsivo.png" width="100%">
            </div>

            <div v-if="plan === '1'" style="padding: 0 10%; text-align: center">
                <img @click="selectProduct" src="@/assets/planos-details/Card 01 Texto.svg" style="width: 100%; max-width: 400px; margin-top: 10px;">
            </div>
            <div v-if="plan === '2'" style="padding: 0 10%; text-align: center">
                <img @click="selectProduct" src="@/assets/planos-details/Card 02 Texto.svg" style="width: 100%; max-width: 400px; margin-top: 10px;">
            </div>
            <div v-if="plan === '3'" style="padding: 0 10%; text-align: center">
                <img @click="selectProduct" src="@/assets/planos-details/Card 03 Texto.svg" style="width: 100%; max-width: 400px; margin-top: 10px;">
            </div>

            <div style="padding: 20px 10% 0 10%; margin-left: 7px; font-size: 18px; font-family: 'Montserrat Black 900', sans-serif;">Confira nossos diferenciais do <br> <span style="font-weight: bold;">Curso de 1ª fase da OAB:</span></div>

            <div class="panel-navigation" style="padding-bottom: 30px; margin-top: 10px;">
                <div class="panel-container" ref="panelContainer" v-if="plan === '1'">
                    <div class="panel-cards b-shadow" style="margin-left: 10vw;"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 01.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 02.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 03.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 04.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 05.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 06.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 07.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 08.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 09.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 10.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 11.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 12.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 13.png" width="100%"></div>
                    <div class="panel-cards b-shadow" style="margin-right: 10vw;"><img src="@/assets/planos-details/essencial/página compra 02 - ESSENCIAL - rodapé card 14.png" width="100%"></div>
                </div>
                <div class="panel-container" ref="panelContainer" v-if="plan === '2'">
                    <div class="panel-cards b-shadow" style="margin-left: 10vw;"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 01.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 02.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 03.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 04.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 05.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 06.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 07.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 08.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 09.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 10.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 11.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 12.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 13.png" width="100%"></div>
                    <div class="panel-cards b-shadow" style="margin-right: 10vw;"><img src="@/assets/planos-details/premium/página compra 01 - PREMIUM - rodapé card 14.png" width="100%"></div>
                </div>
                <div class="panel-container" ref="panelContainer" v-if="plan === '3'">
                    <div class="panel-cards b-shadow" style="margin-left: 10vw;"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 00.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 01.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 02.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 03.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 04.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 05.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 06.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 07.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 08.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 09.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 10.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 11.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 12.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 13.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 14.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 15.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 16.png" width="100%"></div>
                    <div class="panel-cards b-shadow"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 17.png" width="100%"></div>
                    <div class="panel-cards b-shadow" style="margin-right: 10vw;"><img src="@/assets/planos-details/combo/página compra 03 - COMBO - rodapé CARD 18.png" width="100%"></div>
                </div>
                <button @click="scrollLeft" class="scroll-button scroll-button-left"><i class="pi pi-chevron-left" /></button>
                <button @click="scrollRight" class="scroll-button scroll-button-right"><i class="pi pi-chevron-right" /></button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            plan: '1',
            screenWidth: window.innerWidth,
            SCROLL_PX: 420
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenWidth);
        if (this.$route.query.plan && (this.$route.query.plan === '1' || this.$route.query.plan === '2' || this.$route.query.plan === '3')) {
            this.plan = this.$route.query.plan
        } else {
            this.plan = '1'
        }
    },
    components: {

    },
    methods: {
        scrollLeft() {
            this.$refs.panelContainer.scrollBy({
                left: -this.SCROLL_PX,
                behavior: 'smooth'
            });
        },
        scrollRight() {
            this.$refs.panelContainer.scrollBy({
                left: this.SCROLL_PX,
                behavior: 'smooth'
            });
        },
        getImageUrl(plan) {
            return require(`@/assets/planos-details/Card 0${plan}.png`);
        },
        selectProduct() {
            localStorage.setItem('product', this.plan);
            if (!localStorage.getItem('userId')) {
                this.$router.push({ name: 'home', query: { openDialog: true } });
                return;
            }
            this.$router.push('/payment');
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }
};
</script>

<style scoped>
.plans-container {
    background-color: #DFE6EC;
    width: 100%;
    padding: 50px 10%;
}

.plans-container-show-menu {
    background-color: #DFE6EC;
    width: 100%;
    padding: 25px 10%;
}

.plans-title {
    font-family: 'Montserrat Black 900', sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 5px;
}

.b-shadow{
    box-shadow: none !important;
}

</style>