import Vue from 'vue';
import Router from 'vue-router';
import AppHome from '../components/AppHome.vue';
import AppPlanos from '../components/HomeComponents/AppPlanos.vue';
import AppPayment from '../components/AppPayment.vue';
import AppHomeCourse from '../components/AppHomeCourse.vue';
import AppForgotPassword from '../components/AppForgotPassword.vue';
import AppResetForgotPassword from '@/components/AppResetForgotPassword.vue'
import AppChangeData from '@/components/AppChangeData.vue';
import AppRegister from '@/components/AppRegister.vue';
import AppVideo from '@/components/AppVideo.vue'
import AppExamesFGV from '@/components/AppExamesFGV.vue';
import AppSimulado from '@/components/Simulados/AppSimulado.vue';
import AppAnaliseDeDesempenho from '@/components/Simulados/AppAnaliseDeDesempenho.vue';
import AppSimuladoDeFora from '@/components/Simulados/AppSimuladoDeFora.vue';
import AppPlanosDetails from '@/components/HomeComponents/AppPlanosDetails.vue';
import NotFound from '@/components/NotFound.vue';

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
            throw err;
        }
    });
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
            throw err;
        }
    });
};

const isLoggedIn = () => {
    return localStorage.getItem('token') !== null;
};

const authGuard = (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (isLoggedIn()) {
            next();
        } else {
            next('/');
        }
    } else {
        next();
    }
};

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: AppHome,
            children: [
                {
                    path: '',
                    name: 'home',
                    component: AppHome,
                    props: route => ({
                        openDialog: route.query.openDialog === 'true',
                        goTo: route.query.goTo === ''
                    })
                },
                {
                    path: 'register',
                    name: 'register',
                    component: AppRegister
                },
                {
                    path: 'forgotPassword',
                    name: 'forgotPassword',
                    component: AppForgotPassword
                },
                {
                    path: 'resetforgotPassword/:token',
                    name: 'resetforgotPassword',
                    component: AppResetForgotPassword
                },
                {
                    path: 'plans',
                    name: 'plans',
                    component: AppPlanos
                },
                {
                    path: 'details',
                    name: 'details',
                    component: AppPlanosDetails,
                    props: route => ({ plan: route.query.plan === '1' || route.query.plan === '2' || route.query.plan === '3' })
                },
                {
                    path: 'payment',
                    name: 'payment',
                    component: AppPayment
                },
                {
                    path: 'simulado',
                    name: 'simulado-out',
                    component: AppSimuladoDeFora
                }
            ]
        },
        {
            path: '/homeCourse',
            component: AppHomeCourse,
            meta: { requiresAuth: true },
            children: [
                {
                    path: 'disciplinas/:item',
                    name: 'disciplinas',
                    component: AppVideo,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'tecnicas-de-estudo',
                    name: 'tecnicas-de-estudo',
                    component: AppVideo,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'inteligencia-emocional',
                    name: 'inteligencia-emocional',
                    component: AppVideo,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'exames-FGV',
                    name: 'exames-FGV',
                    component: AppExamesFGV,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'simulado',
                    name: 'simulado',
                    component: AppSimulado,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'analise-de-desempenho',
                    name: 'analise-de-desempenho',
                    component: AppAnaliseDeDesempenho,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'changeData',
                    name: 'changeData',
                    component: AppChangeData,
                    meta: { requiresAuth: true }
                }
            ]
        },
        {
            path: '*',
            name: 'NotFound',
            component: NotFound
        }
    ]
});
router.beforeEach(authGuard);

export default router;
