<template>
    <div>
        <Toast />
        <div style="display: flex; justify-content: center; align-items: center; display: flex; height: calc(100vh - 155px);" v-if="showMenu">
            <div style="background-color: white; border-radius: 5px; height: 250px; width: 340px; margin: 20px; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                <div style="height: 75%; border-bottom: 1px solid #cccccc; padding: 5%;">
                    <div style="font-weight: bold; font-size: 19px; margin-bottom: 10px;">Simulado - 1ª Fase</div>
                    <div style="margin-bottom: 8px;"><i class="pi pi-calendar" /> Data da prova: 19/05/2024</div>
                    <div style="margin-bottom: 8px;"><i class="pi pi-pencil" /> Tipos de questões: Múltipla escolha de A a D</div>
                    <div><i class="pi pi-book" /> Instituição: OAB</div>
                </div>
    
                <div class="button-style" @click="goToProva('fase')">Começar</div>
            </div>
            <div style="background-color: white; border-radius: 5px; height: 250px; width: 340px; margin: 20px; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                <div style="height: 75%; border-bottom: 1px solid #cccccc; padding: 5%;">
                    <div style="font-weight: bold; font-size: 19px; margin-bottom: 10px;">Simulado - Personalizável</div>
                    <div style="margin-bottom: 8px;"><i class="pi pi-calendar" /> Data da prova: 19/05/2024</div>
                    <div style="margin-bottom: 8px;"><i class="pi pi-pencil" /> Tipos de questões: Múltipla escolha de A a D</div>
                    <div><i class="pi pi-book" /> Instituição: OAB</div>
                </div>
    
                <div class="button-style" @click="goToProva('perso')">Começar</div>
            </div>
        </div>
        <div v-else style="padding: 0 10%;">
            <div style="border-bottom: 1px solid #cccccc; padding: 15px 0 0 15px; height: 50px;">Preencha o gabarito abaixo conforme suas respostas na prova:</div>
            <div style="display: flex; width: 100%; border-bottom: 1px solid #cccccc;">
                <div style="width: 60%; height: 500px; overflow: auto; border-right: 1px solid #cccccc;">
                    <div v-for="(question, index) in questions" :key="question.id" style="margin: 20px;">
                        <span style="font-weight: bold;">{{ index + 1 }} - </span> {{ question.question }}

                        <br><span style="font-weight: bold;">a. </span>{{ question.a }}
                        <br><span style="font-weight: bold;">b. </span>{{ question.b }}
                        <br><span style="font-weight: bold;">c. </span>{{ question.c }}
                        <br><span style="font-weight: bold;">d. </span>{{ question.d }}
                    </div>
                </div>
                <div style="width: 40%; height: 500px; overflow: auto;">
                    <div v-for="(question, index) in results" :key="question.id" style="margin: 20px;">
                        <div style="display: flex;">
                            <span style="font-weight: bold; margin-right: 10px;">{{ index + 1 }} - </span>
    
                            <RadioButton :id="'a-' + index" value="a" v-model="results[index].awnser" />
                            <label :for="'a-' + index" style="margin: 0 10px 0 3px;">A</label>
    
                            <RadioButton :id="'b-' + index" value="b" v-model="results[index].awnser" />
                            <label :for="'b-' + index" style="margin: 0 10px 0 3px;">B</label>
    
                            <RadioButton :id="'c-' + index" value="c" v-model="results[index].awnser" />
                            <label :for="'c-' + index" style="margin: 0 10px 0 3px;">C</label>
    
                            <RadioButton :id="'d-' + index" value="d" v-model="results[index].awnser" />
                            <label :for="'d-' + index" style="margin: 0 10px 0 3px;">D</label>
                        </div>
                    </div>
                </div>
            </div>
            <div style="background-color: #F2F2F2; height: 120px; padding: 40px;">
                <div class="button-style-enviar" @click="sendSimulate">Enviar</div>
            </div>
        </div>

        <b-modal v-model="dialogPerso" size="lg" centered class="custom-dialog">
            <div style="overflow: auto;">
                O Simulado - Personalizável estará disponível a partir de 02/09/2024
            </div>
            <template v-slot:modal-footer>
                <b-button variant="secondary" @click="dialogPerso = false">Fechar</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import apiClient from '@/services/api'
import Toast from 'primevue/toast';
import RadioButton from 'primevue/radiobutton';

export default {
    components: {
        Toast, RadioButton
    },
    data() {
        return {
            dialogPerso: false,
            showMenu: true,
            questions: null,
            results: null
        };
    },
    methods: {
        goToProva(val){
            if(val === 'fase'){
                this.getSimulate()
                return
            }

            this.dialogPerso = true
        },
        async getSimulate() {
            try {
                let response = await apiClient.get('/simulate');
                this.questions = response.data
                this.results = this.questions.map(element => ({id: element.id, awnser: ''}))
                this.showMenu =  false
            } catch (error) {
                this.showMenu =  true
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: "Não foi possível carregar o Simulado", life: 3000 });
            }
        },
        async sendSimulate(){
            let params = {
                "login": localStorage.getItem('userId'),
                "awnsers": this.results
            }

            try {
                await apiClient.post('/simulate', params);
                this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: "Simulado enviado com sucesso", life: 3000 });
                this.$router.push(`/homeCourse/analise-de-desempenho`)
            } catch (error) {
                this.showMenu =  true
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: "Não foi possível enviar o Simulado", life: 3000 });
            }
        }
    }
};

</script>

<style scoped>
.button-style{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 150px;
    color: #00A3B4;
    margin: 12px auto 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}

.button-style-enviar{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 200px;
    color: #00A3B4;
    margin: 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style-enviar:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}

</style>