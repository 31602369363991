<template>
    <div style="background-color: #4D4D4D; margin-top: 50px;" class="largura-rodape">
        <div class="logo-rodape" @click="goToId('home')">
            <img src="@/assets/logo-home.svg" style="filter: brightness(0) invert(1);">
        </div>
        <div style="width: 100%; display: flex; color: white;" v-if="screenWidth > 1024">
            <div style="flex: 1; height: 230px; border-right: 3px #5E5E5E solid; padding-right: 20px; padding-left: 20px;">
                <div style="font-weight: bold; font-size: 18px;">Quem Somos</div>
                <div style="height: 3px; background-color: #B3925E; width: 50px; margin: 18px 0;"></div>
                <div style="cursor: pointer; margin-bottom: 18px;" @click="goToId('quem-somos')">Nossa História</div>
                <div style="cursor: pointer; margin-bottom: 18px;" @click="goToId('corpo-docente')">Corpo Docente</div>
                <div style="cursor: pointer; margin-bottom: 18px;" @click="goToId('missao-visao-valores')">Missão, Visão, Valores</div>
            </div>
            <div style="flex: 1; height: 230px; padding-left: 40px;">
                <div style="font-weight: bold; font-size: 18px;">Nossos Cursos</div>
                <div style="height: 3px; background-color: #B3925E; width: 50px; margin: 18px 0;"></div>
                <div style="cursor: pointer; margin-bottom: 18px;" @click="$router.push({ name: 'details', query: { plan: '1' } });">1ª Fase - ESSENCIAL</div>
                <div style="cursor: pointer; margin-bottom: 18px;" @click="$router.push({ name: 'details', query: { plan: '2' } });">1ª Fase - PREMIUM</div>
                <div style="cursor: pointer; margin-bottom: 18px;" @click="$router.push({ name: 'details', query: { plan: '3' } });">1ª + 2ª Fase - PREMIUM</div>
            </div>
            <div style="flex: 1; height: 230px; padding-left: 40px;">
                <div style="font-weight: bold; font-size: 18px;">Mídias Sociais</div>
                <div style="height: 3px; background-color: #B3925E; width: 50px; margin: 18px 0;"></div>
                <div style="cursor: pointer; margin-bottom: 18px;" @click="goToLink('https://www.youtube.com/@AprojurOficial')" >
                    <i style="font-size: 30px;" class="pi pi-youtube" />
                    <span style="position: relative; bottom: 9px; margin-left: 10px;">@aprojuroficial</span>
                </div>
                <div style="cursor: pointer; margin-bottom: 18px;" @click="goToLink('https://www.facebook.com/AprojurOficial/')" >
                    <i style="font-size: 30px;" class="pi pi-facebook" />
                    <span style="position: relative; bottom: 9px; margin-left: 10px;">@aprojuroficial</span>
                </div>
                <div style="cursor: pointer; margin-bottom: 18px;" @click="goToLink('https://www.instagram.com/aprojuroficial/')">
                    <i style="font-size: 30px;" class="pi pi-instagram" />
                    <span style="position: relative; bottom: 9px; margin-left: 10px;">@aprojuroficial</span>
                </div>
            </div>
            <div style="flex: 1; height: 230px; padding-left: 40px;">
                <div style="font-weight: bold; font-size: 18px;">Canais de Atendimento</div>
                <div style="height: 3px; background-color: #B3925E; width: 50px; margin: 18px 0;"></div>
                <div style="display: flex; align-items: center; cursor: pointer; margin-bottom: 18px;" @click="openWhatsAppLink">
                    <div style="height: 25px; width: 25px; margin-right: 10px;">
                        <i style="font-size: 25px;" class="pi pi-whatsapp" />
                    </div>
                    <div>(41) 9602-1592</div>
                </div>
                <div style="display: flex; align-items: center; cursor: pointer; margin-bottom: 18px;">
                    <div style="height: 25px; width: 25px; margin-right: 10px;">
                        <i style="font-size: 25px;" class="pi pi-envelope" />
                    </div>
                    <div>aprojuroficial@gmail.com</div>
                </div>
                <div style="display: flex; align-items: center; cursor: pointer; margin-bottom: 18px;">
                    <div style="height: 25px; width: 25px; margin-right: 10px;">
                        <i style="font-size: 25px;" class="pi pi-map-marker" />
                    </div>
                    <div>Sede em Curitiba - PR</div>
                </div>
                <div style="display: flex; align-items: center; cursor: pointer; margin-bottom: 18px;" @click="dialogTermos = true">
                    <div style="height: 25px; width: 25px; margin-right: 10px;">
                        <i style="font-size: 25px;" class="pi pi-lock" />
                    </div>
                    <div>Política de Privacidade</div>
                </div>
            </div>
        </div>
        <b-accordion v-else :multiple="true">
            <b-card no-body style="border: none; border-radius: 0; background-color: #4D4D4D; color: white; border-top: 1px solid #8a8888;">
                <b-button block v-b-toggle.accordion-1 style="background-color: #4D4D4D; text-align: left; border-color: #4D4D4D; font-weight: bold; font-size: 18px; box-shadow: none; height: 60px; justify-content: space-between; display: flex; align-items: center;">
                    <span>Canais de Atendimento</span>
                    <i class="pi pi-chevron-down"/>
                </b-button>
                <b-collapse id="accordion-1">
                    <b-card-body style="position: relative; bottom: 12px; right: 10px;">
                        <div style="height: 3px; background-color: #B3925E; width: 50px; margin: 0 0 18px 0; position: relative; bottom: 7px;"></div>
                        <div style="display: flex; align-items: center; cursor: pointer; margin-bottom: 18px;" @click="openWhatsAppLink">
                            <div style="height: 25px; width: 25px; margin-right: 10px;">
                            <i style="font-size: 25px;" class="pi pi-whatsapp"></i>
                            </div>
                            <div>(41) 9602-1592</div>
                        </div>
                        <div style="display: flex; align-items: center; cursor: pointer; margin-bottom: 18px;">
                            <div style="height: 25px; width: 25px; margin-right: 10px;">
                            <i style="font-size: 25px;" class="pi pi-envelope"></i>
                            </div>
                            <div>aprojuroficial@gmail.com</div>
                        </div>
                        <div style="display: flex; align-items: center; cursor: pointer; margin-bottom: 18px;">
                            <div style="height: 25px; width: 25px; margin-right: 10px;">
                            <i style="font-size: 25px;" class="pi pi-map-marker"></i>
                            </div>
                            <div>Sede em Curitiba - PR</div>
                        </div>
                        <div style="display: flex; align-items: center; cursor: pointer;" @click="dialogTermos = true">
                            <div style="height: 25px; width: 25px; margin-right: 10px;">
                            <i style="font-size: 25px;" class="pi pi-lock"></i>
                            </div>
                            <div>Política de Privacidade</div>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body style="border: none; border-radius: 0; background-color: #4D4D4D; color: white; border-top: 1px solid #8a8888;">
                <b-button block v-b-toggle.accordion-2 style="background-color: #4D4D4D; text-align: left; border-color: #4D4D4D; font-weight: bold; font-size: 18px; box-shadow: none; height: 60px; justify-content: space-between; display: flex; align-items: center;">
                    <span>Quem Somos</span>
                    <i class="pi pi-chevron-down" />
                </b-button>
                <b-collapse id="accordion-2">
                    <b-card-body style="position: relative; bottom: 12px; right: 10px;">
                        <div style="height: 3px; background-color: #B3925E; width: 50px; margin: 0 0 18px 0; position: relative; bottom: 7px;"></div>
                        <div style="cursor: pointer; margin-bottom: 18px;" @click="goToId('quem-somos')">Nossa História</div>
                        <div style="cursor: pointer; margin-bottom: 18px;" @click="goToId('corpo-docente')">Corpo Docente</div>
                        <div style="cursor: pointer;" @click="goToId('missao-visao-valores')">Missão, Visão, Valores</div>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body style="border: none; border-radius: 0; background-color: #4D4D4D; color: white; border-top: 1px solid #8a8888;">
                <b-button block v-b-toggle.accordion-3 style="background-color: #4D4D4D; text-align: left; border-color: #4D4D4D; font-weight: bold; font-size: 18px; box-shadow: none; height: 60px; justify-content: space-between; display: flex; align-items: center;">
                    <span>Nossos Cursos</span>
                    <i class="pi pi-chevron-down" />
                </b-button>
                <b-collapse id="accordion-3">
                    <b-card-body style="position: relative; bottom: 12px; right: 10px;">
                        <div style="height: 3px; background-color: #B3925E; width: 50px; margin: 0 0 18px 0; position: relative; bottom: 7px;"></div>
                        <div style="cursor: pointer; margin-bottom: 18px;" @click="$router.push({ name: 'details', query: { plan: '1' } });">1ª Fase - ESSENCIAL</div>
                        <div style="cursor: pointer; margin-bottom: 18px;" @click="$router.push({ name: 'details', query: { plan: '2' } });">1ª Fase - PREMIUM</div>
                        <div style="cursor: pointer;" @click="$router.push({ name: 'details', query: { plan: '3' } });">1ª + 2ª Fase - PREMIUM</div>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body style="border: none; border-radius: 0; background-color: #4D4D4D; color: white; border-top: 1px solid #8a8888;">
                <b-button block v-b-toggle.accordion-4 style="background-color: #4D4D4D; text-align: left; border-color: #4D4D4D; font-weight: bold; font-size: 18px; box-shadow: none; height: 60px; justify-content: space-between; display: flex; align-items: center;">
                    <span>Mídias Sociais</span>
                    <i class="pi pi-chevron-down" />
                </b-button>
                <b-collapse id="accordion-4">
                    <b-card-body style="position: relative; bottom: 12px; right: 10px;">
                        <div style="height: 3px; background-color: #B3925E; width: 50px; margin: 0 0 18px 0; position: relative; bottom: 7px;"></div>
                        <div style="cursor: pointer; margin-bottom: 18px;" @click="goToLink('https://www.youtube.com/@AprojurOficial')">
                            <i style="font-size: 30px;" class="pi pi-youtube"></i>
                            <span style="position: relative; bottom: 9px; margin-left: 10px;">@aprojuroficial</span>
                        </div>
                        <div style="cursor: pointer; margin-bottom: 18px;" @click="goToLink('https://www.facebook.com/AprojurOficial/')">
                            <i style="font-size: 30px;" class="pi pi-facebook"></i>
                            <span style="position: relative; bottom: 9px; margin-left: 10px;">@aprojuroficial</span>
                        </div>
                        <div style="cursor: pointer;" @click="goToLink('https://www.instagram.com/aprojuroficial/')">
                            <i style="font-size: 30px;" class="pi pi-instagram"></i>
                            <span style="position: relative; bottom: 9px; margin-left: 10px;">@aprojuroficial</span>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </b-accordion>
        
        <div class="legenda">
            Copyright 2024 Aprojur. Todos os direitos reservados. Aprojur Preparação Jurídica - LTDA | CNPJ: 54.852.293/0001-64
        </div>

        <b-modal v-model="dialogTermos" size="lg" centered class="custom-dialog">
            <div style="height: 500px; overflow: auto;">
                <div class="section">
                    <h2>1. Aceitação dos Termos</h2>
                    <p>Ao acessar e utilizar a plataforma AproJur, você concorda em cumprir e estar vinculado aos seguintes Termos de Uso. Caso não concorde com estes termos, não utilize nossa plataforma.</p>
                </div>
                <div class="section">
                    <h2>2. Cadastro e Acesso</h2>
                    <p>2.1. Para acessar os cursos oferecidos, o usuário deve se cadastrar fornecendo informações verdadeiras e completas.</p>
                    <p>2.2. O usuário é responsável por manter a confidencialidade de suas credenciais de acesso e por todas as atividades que ocorrem em sua conta.</p>
                </div>
                <div class="section">
                    <h2>3. Pagamento</h2>
                    <p>3.1. O acesso aos cursos é condicionado ao pagamento das taxas estabelecidas.</p>
                    <p>3.2. Todos os pagamentos são processados por meio de nossos parceiros de pagamento seguro. Não armazenamos informações de cartão de crédito.</p>
                    <p>3.3. As taxas pagas não são reembolsáveis, exceto em casos específicos conforme descrito em nossa política de reembolso.</p>
                </div>
                <div class="section">
                    <h2>4. Uso da Plataforma</h2>
                    <p>4.1. O usuário se compromete a utilizar a plataforma de maneira ética e legal.</p>
                    <p>4.2. É proibido compartilhar o conteúdo dos cursos, exceto com permissão expressa por escrito de AproJur.</p>
                    <p>4.3. Qualquer tentativa de hackear ou explorar vulnerabilidades da plataforma resultará na suspensão imediata da conta e possíveis ações legais.</p>
                </div>
                <div class="section">
                    <h2>5. Direitos Autorais</h2>
                    <p>5.1. Todo o conteúdo disponibilizado na plataforma, incluindo vídeos, textos, imagens e materiais de apoio, é protegido por direitos autorais.</p>
                    <p>5.2. O usuário não pode reproduzir, distribuir ou criar obras derivadas de qualquer conteúdo sem a autorização prévia e por escrito de AproJur.</p>
                </div>
                <div class="section">
                    <h2>6. Limitação de Responsabilidade</h2>
                    <p>6.1. AproJur não se responsabiliza por qualquer dano direto ou indireto resultante do uso ou da incapacidade de uso da plataforma.</p>
                    <p>6.2. Não garantimos que os cursos atenderão às expectativas ou que serão livres de erros.</p>
                </div>
                <div class="section">
                    <h2>7. Modificações nos Termos de Uso</h2>
                    <p>7.1. Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. As mudanças serão comunicadas aos usuários e entrarão em vigor imediatamente após a publicação.</p>
                </div>
                <div class="section">
                    <h2>8. Política de Privacidade</h2>
                    <p>8.1. O uso da plataforma também é regido por nossa Política de Privacidade, que descreve como coletamos, usamos e protegemos as informações pessoais dos usuários.</p>
                </div>
                <div class="section">
                    <h2>9. Disposições Gerais</h2>
                    <p>9.1. Estes Termos de Uso constituem o acordo completo entre o usuário e AproJur e substituem todos os acordos anteriores.</p>
                    <p>9.2. Caso alguma disposição destes Termos de Uso seja considerada inválida, as demais disposições continuarão em pleno vigor e efeito.</p>
                </div>
            </div>
            <template v-slot:modal-footer>
                <b-button variant="secondary" @click="dialogTermos = false">Fechar</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogTermos: false
        };
    },
    methods: {
        goToLink(url) {
            window.open(url, '_blank');
        },
        openWhatsAppLink() {
            window.open('https://wa.me/4196021592?text=Ol%C3%A1', '_blank');
        },
        goToId(val){
            console.log("Teste");
            this.$emit('goToId', val);
        }
    },
    components: {
        
    },
    props: ['screenWidth']
}
</script>

<style scoped>

.largura-rodape{
    padding: 30px 10% 0 10%;
}

.logo-rodape{
    margin-top: 20px;
    margin-bottom: 50px;
}

.legenda {
    width: 100%;
    height: 70px;
    font-size: 15px;
    margin-top: 50px;
    border-top: #8a8888 solid 1px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

@media (min-width: 1025px) and (max-width: 1138px) {
    .largura-rodape{
        padding: 30px 0 0 0;
    }

    .logo-rodape{
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media (max-width: 1024px) {
    .logo-rodape{
        height: 50px;
        margin: 0;
    }

    .logo-rodape img{
        width: 140px;
    }

    .legenda {
        height: auto;
        padding: 20px;
        font-size: 12px;
        margin-top: 0;
    }
}

</style>