var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"home-container"},[_c('div',{staticClass:"home-title"},[_vm._v("Corpo Docente")]),_c('div',{staticStyle:{"width":"135px","height":"3px","background-color":"#B99660","margin":"15px 0"}}),_vm._m(0),(_vm.screenWidth > 1024)?_c('div',{staticStyle:{"display":"flex","margin-top":"40px"}},[_vm._m(1),_vm._m(2)]):_c('div',{staticStyle:{"margin-top":"40px"}},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-family":"'Montserrat Regular 400'"}},[_vm._v("Esses são os professores do curso preparatório OAB, especialistas em suas áreas e "),_c('br'),_vm._v(" dedicados a proporcionar o melhor conteúdo para ajudar na aprovação no exame:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-right":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 01.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-right":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 02.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-right":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 03.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-right":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 04.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-right":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 05.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-right":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 06.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-right":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 07.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-right":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 08.svg"),"width":"100%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-left":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 09.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-left":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 10.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-left":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 11.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-left":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 12.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-left":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 13.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-left":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 14.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-left":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 15.svg"),"width":"100%"}})]),_c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","margin-left":"10px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 16.svg"),"width":"100%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 01.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 02.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 03.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 04.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 05.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 06.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 07.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 08.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 09.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 10.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 11.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 12.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 13.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 14.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 15.svg"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-cards",staticStyle:{"width":"calc(100% - 10px)","margin-bottom":"20px","background-color":"white"}},[_c('img',{attrs:{"src":require("@/assets/professores/prof 16.svg"),"width":"100%"}})])
}]

export { render, staticRenderFns }