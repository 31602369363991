<template>
    <div style="padding: 0 10%;">
        <div style="border-bottom: 1px solid #cccccc; padding: 15px 0 0 15px; height: 70px; display: flex; justify-content: space-between;">
            <span style="margin-top: 8px;">Análise de Desempenho do simulado</span>
            <div v-if="!showList" class="button-style" style="margin: 0 !important;" @click="showList = true"><i class="pi pi-chevron-left mr-2" /> Voltar</div>
        </div>
        <div style="display: flex; height: calc(100vh - 155px);" >
            <div v-if="showList" style="display: flex; flex-wrap: wrap;">
                <div v-for="item in results" :key="item.id" style="background-color: white; border-radius: 5px; height: 230px; width: 340px; margin: 15px; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                    <div style="height: 72%; border-bottom: 1px solid #cccccc; padding: 5%;">
                        <div style="font-weight: bold; font-size: 19px; margin-bottom: 10px;">Simulado - 1ª Fase</div>
                        <div style="margin-bottom: 8px;"><i class="pi pi-calendar" /> Data da prova: {{ convertDate(item.data_simulate) }}</div>
                        <div style="margin-bottom: 8px;"><i class="pi pi-pencil" /> Porcentagem de acertos: {{item.percent_result}}%</div>
                        <div><i class="pi pi-book" /> Instituição: OAB</div>
                    </div>
        
                    <div class="button-style" @click="getAnalise(item.id)">Analisar</div>
                </div>
            </div>
            <div v-else>
                <div v-for="item in analise" :key="item.id">
                    {{ item.subTopic }} - {{ item.rightQuestions }} / {{ item.quantityQuestions }} - {{ ((item.rightQuestions / item.quantityQuestions) * 100).toFixed(2) }}%
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from '@/services/api'

export default {
    mounted() {
        this.getSimulates()
    },
    components: {
        
    },
    data() {
        return {
            results: null,
            showList: true,
            analise: null            
        };
    },
    methods: {
        async getSimulates(){
            try {
                let response = await apiClient.get(`/simulate/list/${localStorage.getItem('userId')}`);
                this.results = response.data.sort((a, b) => {
                    return new Date(b.data_simulate) - new Date(a.data_simulate);
                });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: "Não foi possível carregar o Simulado", life: 3000 });
            }
        },
        async getAnalise(val){
            try {
                let response = await apiClient.get(`/simulate/result/${val}`);
                this.analise = response.data
                this.showList = false
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: "Não foi possível carregar o Simulado", life: 3000 });
            }
        },
        convertDate(val){
            let data = new Date(val);

            let opcoes = {
                timeZone: 'America/Sao_Paulo',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            };

            let dataBrasil = data.toLocaleString('pt-BR', opcoes);
            return dataBrasil
        }
    }
};
</script>

<style scoped>
.button-style{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 150px;
    color: #00A3B4;
    margin: 12px auto 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}
</style>