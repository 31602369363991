<template>
    <div>
        <Toast />

        <div class="container">
            <div class="title">Recupere sua Senha!</div>
            <h6>Informe a nova Senha.</h6>

            <div class="item-style">
                <span>Senha *</span><br>
                <Password class="w-100" :feedback="false" v-model="password" toggleMask />
                <div v-if="!isValidForm && isValidValue(password)" class="p-error">Campo obrigatório.</div>
            </div>

            <div class="button-style" @click="send">Enviar</div>
        </div>
    </div>
</template>

<script>
import apiClient from '@/services/api';
import Password from 'primevue/password/Password';
import Toast from 'primevue/toast/Toast';

export default {
    components: {
        Toast,
        Password
    },
    data() {
        return {
            password: '',
            isValidForm: true
        };
    },
    methods: {
        async send() {
            if(this.isValidValue(this.password)){
                this.isValidForm = false
                return
            }

            try {
                await apiClient.post('/changePassword', { 
                    token: this.$route.params.token,
                    password: this.password 
                });
                this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Senha alterada com sucesso', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao alterar senha', life: 3000 });
            }
        },
        isValidValue(val){
            return val.trim() === ''
        }
    }
};
</script>

<style scoped>

.container{
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.title{
    padding-top: 30px;
    margin: 0 0 10px 0;
    font-size: 25px;
    font-weight: bold;
}

.button-style{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 150px;
    color: #00A3B4;
    margin: 25px auto 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}

.item-style{
    text-align: left;
    margin-top: 15px;
}

.w-100{
    width: 100%;
}

</style>