<template>
    <div>
        <div class="home-container">
            <div class="home-title">Corpo Docente</div>
            <div style="width: 135px; height: 3px; background-color: #B99660; margin: 15px 0;"></div>
            <div style="font-family: 'Montserrat Regular 400'">Esses são os professores do curso preparatório OAB, especialistas em suas áreas e <br> dedicados a proporcionar o melhor conteúdo para ajudar na aprovação no exame:</div>

            <div style="display: flex; margin-top: 40px;" v-if="screenWidth > 1024">
                <div style="flex: 1;">
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-right: 10px; background-color: white;"><img src="@/assets/professores/prof 01.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-right: 10px; background-color: white;"><img src="@/assets/professores/prof 02.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-right: 10px; background-color: white;"><img src="@/assets/professores/prof 03.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-right: 10px; background-color: white;"><img src="@/assets/professores/prof 04.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-right: 10px; background-color: white;"><img src="@/assets/professores/prof 05.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-right: 10px; background-color: white;"><img src="@/assets/professores/prof 06.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-right: 10px; background-color: white;"><img src="@/assets/professores/prof 07.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-right: 10px; background-color: white;"><img src="@/assets/professores/prof 08.svg" width="100%"></div>
                </div>
                <div style="flex: 1;">
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-left: 10px; background-color: white;"><img src="@/assets/professores/prof 09.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-left: 10px; background-color: white;"><img src="@/assets/professores/prof 10.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-left: 10px; background-color: white;"><img src="@/assets/professores/prof 11.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-left: 10px; background-color: white;"><img src="@/assets/professores/prof 12.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-left: 10px; background-color: white;"><img src="@/assets/professores/prof 13.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-left: 10px; background-color: white;"><img src="@/assets/professores/prof 14.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-left: 10px; background-color: white;"><img src="@/assets/professores/prof 15.svg" width="100%"></div>
                    <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; margin-left: 10px; background-color: white;"><img src="@/assets/professores/prof 16.svg" width="100%"></div>
                </div>
            </div>
            <div style="margin-top: 40px;" v-else>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 01.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 02.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 03.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 04.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 05.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 06.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 07.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 08.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 09.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 10.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 11.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 12.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 13.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 14.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 15.svg" width="100%"></div>
                <div class="panel-cards" style="width: calc(100% - 10px); margin-bottom: 20px; background-color: white;"><img src="@/assets/professores/prof 16.svg" width="100%"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        };
    },
    methods: {

    },
    components: {
        
    },
    props: ['screenWidth']
}
</script>