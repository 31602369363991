<template>
    <div id="app">
        <ProgressSpinner v-if="loading" />
        <router-view v-else />
    </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
export default {
    components: {
        ProgressSpinner
    },
    data() {
        return {
            loading: true
        }
    },
    created() {
        this.$router.onReady(() => {
            this.loading = false;
        });
    },
    mounted() {
        document.addEventListener('dragstart', function(event) {
            if (event.target.tagName === 'IMG') {
                event.preventDefault();
            }
        });
  }
}
</script>

<style>

@font-face {
    font-family: 'Montserrat Black 900';
    src: url('@/assets/fonts/Montserrat Black 900.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat Thin 250';
    src: url('@/assets/fonts/Montserrat Thin 250.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat Regular 400';
    src: url('@/assets/fonts/Montserrat Regular 400.ttf') format('truetype');
}

#app {
    font-family: 'Montserrat Regular 400';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #DFE6EC;
}
</style>
