<template>
    <div>
        <div class="home-container">
            <div class="home-title">METODOLOGIA <br>APROJUR</div>
            <div style="width: 135px; height: 3px; background-color: #B99660; margin: 15px 0;"></div>
            <div style="font-family: 'Montserrat Regular 400'">Domine as ferramentas e conquiste <br> sua aprovação na
                1ª Fase da OAB!</div>
        </div>

        <div class="panel-navigation">
            <div class="panel-container" ref="panelContainer">
                <div class="panel-cards" style="margin-left: 10vw;"><img src="@/assets/metodologia/Card 01.svg" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/metodologia/Card 02.svg" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/metodologia/Card 03.svg" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/metodologia/Card 04.svg" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/metodologia/Card 05.svg" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/metodologia/Card 06.svg" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/metodologia/Card 07.svg" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/metodologia/Card 08.svg" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/metodologia/Card 09.svg" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/metodologia/Card 10.svg" width="100%"></div>
                <div class="panel-cards"><img src="@/assets/metodologia/Card 11.svg" width="100%"></div>
                <div class="panel-cards" style="margin-right: 10vw;"><img src="@/assets/metodologia/Card 12.svg" width="100%"></div>
            </div>
            <button @click="scrollLeft" class="scroll-button scroll-button-left"><i class="pi pi-chevron-left" /></button>
            <button @click="scrollRight" class="scroll-button scroll-button-right"><i class="pi pi-chevron-right" /></button>
        </div>
        
    </div>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
            SCROLL_PX: 420
        };
    },
    methods: {
        scrollLeft() {
            this.$refs.panelContainer.scrollBy({
                left: -this.SCROLL_PX,
                behavior: 'smooth'
            });
        },
        scrollRight() {
            this.$refs.panelContainer.scrollBy({
                left: this.SCROLL_PX,
                behavior: 'smooth'
            });
        }
    }
};
</script>

<style scoped></style>