<template>
    <div>
        <Toast />
        <div class="home-topo" id="home">
            <div class="home-topo-1">
                <img @click="$router.push('/');" src="@/assets/logo-home.svg">
            </div>

            <div class="home-topo-2">
                <img class="detalhe-branco" v-if="screenWidth > 1024" src="@/assets/detalhe-branco.svg">

                <div class="position-home-topo-2">
                    <div class="minha-conta-button" v-if="screenWidth > 1024">
                        <i class="pi pi-user m-r-icon" />
                        <h6 style="margin-top: 9px; color: black;" @click="openDialog">MINHA CONTA</h6>
                    </div>
                    <div v-else class="button-minha-conta" @click="openDialog">
                        <i class="pi pi-user" />
                    </div>
                    <div v-if="screenWidth > 1024">
                        <img @click="goToLink('https://www.instagram.com/aprojuroficial/')" class="icon-midia" src="@/assets/social/instagram.svg">
                        <img @click="goToLink('https://www.youtube.com/@AprojurOficial')" class="icon-midia" src="@/assets/social/youtube.svg">
                        <img @click="goToLink('https://www.facebook.com/AprojurOficial/')" class="icon-midia" src="@/assets/social/facebook.svg">
                        <img @click="goToLink('https://www.linkedin.com/company/aprojur/')" class="icon-midia" src="@/assets/social/linkedin.svg">
                    </div>
                    <div v-else>
                        <div class="button-style-ham" @click="visibleFull = true"><i class="pi pi-bars" style="font-size: 12px;" /></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu-container" v-if="screenWidth > 1024">
            <div class="button-style" @click="$router.push({ name: 'home', query: { goTo: 'home' } });">HOME</div>
            <div class="button-style" @click="$router.push({ name: 'home', query: { goTo: 'cursos' } });">CURSOS</div>
            <div class="button-style" @click="$router.push({ name: 'home', query: { goTo: 'quem-somos' } });">QUEM SOMOS</div>
            <div class="button-style" @click="$router.push({ name: 'home', query: { goTo: 'contato' } });">CONTATO</div>
        </div>

        <Sidebar :visible.sync="visibleFull" :baseZIndex="1000" position="full">
            <div style="margin-left: 20px; color: black;">
                <div style="font-weight: bold; font-size: 21px; margin-bottom: 10px;" @click="$router.push({ name: 'home', query: { goTo: 'home' } });">HOME</div>
                <div style="font-weight: bold; font-size: 21px; margin-bottom: 10px;" @click="$router.push({ name: 'home', query: { goTo: 'cursos' } });">CURSOS</div>
                <div style="font-weight: bold; font-size: 21px; margin-bottom: 10px;" @click="$router.push({ name: 'home', query: { goTo: 'quem-somos' } });">QUEM SOMOS</div>
                <div style="font-weight: bold; font-size: 21px; margin-bottom: 10px;" @click="$router.push({ name: 'home', query: { goTo: 'contato' } });">CONTATO</div>
                <div style="height: 3px; background-color: #B3925E; width: 50px; margin: 18px 0;"></div>
                <div>
                    <img @click="goToLink('https://www.instagram.com/aprojuroficial/')" style="width: 40px; filter: grayscale(100%);" src="@/assets/social/instagram.svg">
                    <img @click="goToLink('https://www.youtube.com/@AprojurOficial')" style="width: 40px; filter: grayscale(100%);" class="icon-midia" src="@/assets/social/youtube.svg">
                    <img @click="goToLink('https://www.facebook.com/AprojurOficial/')" style="width: 40px; filter: grayscale(100%);" class="icon-midia" src="@/assets/social/facebook.svg">
                    <img @click="goToLink('https://www.linkedin.com/company/aprojur/')" style="width: 40px; filter: grayscale(100%);" class="icon-midia" src="@/assets/social/linkedin.svg">
                </div>
            </div>
        </Sidebar>

        <template v-if="$route.path === '/'">
            <div class="div-carousel" v-if="screenWidth > 1024">
                <b-carousel id="carousel" :interval="5000" controls indicators background="#000000" img-width="1024"
                    img-height="480">
                    <b-carousel-slide v-for="(slide, index) in slides" :key="index" :img-src="slide.src">
                    <div v-if="index != 1" @click="goToScreen(index)" style="cursor: pointer; width: 115%; height: 340px; position: relative; top: 38.2px; right: 7.5%;"></div>
                    </b-carousel-slide>
                </b-carousel>
            </div>
            <div v-else>
                <div class="home-title" style="padding: 0 10%; font-size: 20px; margin-top: 18px;">Preparando Futuros <br> Advogados, <span style="color: #B3925E;">Transformando <br> Carreiras.</span></div>
                <div class="panel-navigation" style="margin-top: 15px;">
                    <div class="panel-container" ref="panelContainer">
                        <div class="panel-cards" @click="$router.push({ name: 'details', query: { plan: '2' } })" style="box-shadow: none; margin-left: 10vw;"><img src="@/assets/home/banner-02-mobile.png" width="100%"></div>
                        <div class="panel-cards" style="box-shadow: none;"><img src="@/assets/home/banner-01-mobile.png" width="100%"></div>
                        <div class="panel-cards" @click="$router.push({ name: 'details', query: { plan: '3' } })" style="box-shadow: none;"><img src="@/assets/home/banner-04-mobile.png" width="100%"></div>
                        <div class="panel-cards" @click="$router.push({ name: 'details', query: { plan: '1' } })" style="box-shadow: none; margin-right: 10vw;"><img src="@/assets/home/banner-03-mobile.png" width="100%"></div>
                    </div>
                    <button @click="scrollLeft" class="scroll-button scroll-button-left"><i class="pi pi-chevron-left" /></button>
                    <button @click="scrollRight" class="scroll-button scroll-button-right"><i class="pi pi-chevron-right" /></button>
                </div>
            </div>
    
            <div id="cursos" class="section">
                <AppPlanos />
            </div>
            <div class="section">
                <AppMetodologia />
            </div>
            <div class="section">
                <AppPQEscolher />
            </div>
            <div class="section">
                <AppDiferencial />
            </div>
            <div class="section">
                <AppExperiencia />
            </div>
            <div id="quem-somos" class="section">
                <AppNossaHistoria :screenWidth="screenWidth" />
            </div>
            <div id="missao-visao-valores" class="section">
                <AppMissaoVisaoValores />
            </div>
            <div id="corpo-docente" class="section">
                <AppProfessores :screenWidth="screenWidth" />
            </div>
            <div id="contato">
                <AppRodape :screenWidth="screenWidth" @goToId="goToId"/>
            </div>
        </template>
        <template v-else>
            <router-view />
        </template>

        <Dialog ref="dialog" :visible.sync="dialogLogin" modal>
            <div class="dialog-style">
                <div style="position: absolute; right: 10px; top: 10px; color: black; cursor: pointer;"
                    @click="dialogLogin = false">
                    <i class="pi pi-times" style="font-size: 18px;"></i>
                </div>
                <div class="block-dialog-login" v-if="loadingLogin">
                    <ProgressSpinner />
                </div>
                <div class="block-dialog-login" v-else>
                    <div class="title-login">Login do Aluno</div>
                    <InputText class="w-100 mt-2" placeholder="Email ou CPF" v-model="formLogin.login" />
                    <div v-if="!isValidLogin && isValidValue(formLogin.login)" class="p-error">Campo obrigatório.</div>
                    <Password class="w-100 mt-2" placeholder="Senha" v-model="formLogin.password" :feedback="false"
                        toggleMask />
                    <div v-if="!isValidLogin && isValidValue(formLogin.password)" class="p-error">Campo obrigatório.
                    </div>
                    <div class="button-login" @click="submitLogin">ENTRAR</div>
                    <div class="forgot-password-dialog" @click="forgotPassword">Esqueci minha senha.</div>
                </div>
                <div class="block-dialog-register" v-if="loadingRegister">
                    <ProgressSpinner />
                </div>
                <div class="block-dialog-register" v-else>
                    <div class="title-register">Crie sua Conta!</div>
                    <InputText class="w-100 mt-2" placeholder="Nome" v-model="formRegister.name" />
                    <div v-if="!isValidRegister && isValidValue(formRegister.name)" class="p-error">Campo obrigatório.
                    </div>
                    <InputText class="w-100 mt-2" placeholder="Email" v-model="formRegister.login" />
                    <div v-if="!isValidRegister && isValidValue(formRegister.login)" class="p-error">Campo obrigatório.
                    </div>
                    <Password class="w-100 mt-2" placeholder="Senha" v-model="formRegister.password" :feedback="false"
                        toggleMask />
                    <div v-if="!isValidRegister && isValidValue(formRegister.password)" class="p-error">Campo
                        obrigatório.</div>
                    <div class="button-register" @click="submitRegister">CRIAR CONTA</div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext/InputText';
import apiClient from '@/services/api'
import Toast from 'primevue/toast';
import Password from 'primevue/password';
import AppPlanos from './HomeComponents/AppPlanos.vue';
import AppMetodologia from './HomeComponents/AppMetodologia.vue';
import AppPQEscolher from './HomeComponents/AppPQEscolher.vue';
import AppDiferencial from './HomeComponents/AppDiferencial.vue';
import AppExperiencia from './HomeComponents/AppExperiencia.vue';
import ProgressSpinner from 'primevue/progressspinner';
import AppRodape from './AppRodape.vue';
import AppMissaoVisaoValores from './HomeComponents/AppMissaoVisaoValores.vue';
import AppProfessores from './HomeComponents/AppProfessores.vue';
import AppNossaHistoria from './HomeComponents/AppNossaHistoria.vue';
import Sidebar from 'primevue/sidebar';
export default {
    data() {
        return {
            slides: [
                { src: require('@/assets/home/banner-01.png') },
                { src: require('@/assets/home/banner-03.png') },
                { src: require('@/assets/home/banner-02.png') },
            ],
            dialogLogin: false,
            formLogin: {
                login: '',
                password: ''
            },
            formRegister: {
                name: '',
                login: '',
                password: ''
            },
            isValidLogin: true,
            isValidRegister: true,
            loadingLogin: false,
            loadingRegister: false,
            visibleFull: false,
            screenWidth: window.innerWidth,
            SCROLL_PX: 420
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenWidth);
        this.visibleFull = false
        if(this.$route.query.openDialog){
            this.openDialog();
        }
        if(this.$route.query.goTo && (this.$route.query.goTo === 'home' || this.$route.query.goTo === 'cursos' || this.$route.query.goTo === 'contato' || this.$route.query.goTo === 'quem-somos')){
            this.scrollToSection(this.$route.query.goTo);
        }
    },
    methods: {
        goToLink(url) {
            window.open(url, '_blank');
        },
        scrollLeft() {
            this.$refs.panelContainer.scrollBy({
                left: -this.SCROLL_PX,
                behavior: 'smooth'
            });
        },
        scrollRight() {
            this.$refs.panelContainer.scrollBy({
                left: this.SCROLL_PX,
                behavior: 'smooth'
            });
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
        goToId(val){
            this.scrollToSection(val);
        },
        goToScreen(val){
            if(val === 1) return;

            this.$router.push({ name: 'details', query: { plan: val === 0 ? '2' : '3' } });
        },
        scrollToSection(sectionId) {
            let targetElement = document.getElementById(sectionId);
            if (!targetElement) {
                setTimeout(() => {
                    this.scrollToSection(sectionId);
                }, 100);
                return;
            }
            if (targetElement) {
                window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: 'smooth'
                });
            }
        },
        async submitLogin() {
            if (this.formLogin.login.trim() === '' || this.formLogin.password.trim() === '') {
                this.isValidLogin = false;
                return
            }

            if (this.formLogin.password.length < 8) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'A senha deve ter no minimo 8 caracteres', life: 3000 });
                return
            }

            try {
                this.loadingLogin = true
                let response = await apiClient.post('/auth/login', this.formLogin);
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('name', response.data.name)
                localStorage.setItem('email', response.data.email)
                localStorage.setItem('userId', response.data.id)
                this.$router.push('/homeCourse');
                this.dialogLogin = false
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.setItem('userId', error.response.data.userId);

                    if (localStorage.getItem('product')) {
                        this.dialogLogin = false
                        this.$router.push('/payment');
                    } else {
                        this.dialogLogin = false
                        this.$router.push('/plans');
                    }
                    return
                }

                if (error.response && error.response.data.error) {
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.error, life: 3000 });
                    return
                }
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: "Erro interno no servidor", life: 3000 });
            } finally {
                this.loadingLogin = false;
            }
        },
        async submitRegister() {
            if (this.formRegister.name.trim() === '' || this.formRegister.login.trim() === '' || this.formRegister.password.trim() === '') {
                this.isValidRegister = false;
                return
            }

            if (this.formRegister.password.length < 8) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'A senha deve ter no minimo 8 caracteres', life: 3000 });
                return
            }

            localStorage.setItem('dataToRegister', JSON.stringify(this.formRegister))
            this.dialogLogin = false
            this.$router.push('/register');
        },
        openDialog() {
            this.visibleFull = false
            this.dialogLogin = true
            this.formLogin = {
                login: '',
                password: ''
            }
            this.formRegister = {
                name: '',
                login: '',
                password: ''
            }
            this.isValidLogin = true
            this.isValidRegister = true
        },
        isValidValue(val) {
            return val.trim() === ''
        },
        forgotPassword() {
            this.dialogLogin = false
            this.$router.push('/forgotPassword');
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth);
    },
    watch:{
        '$route'(val) {
            if(val.path === '/'){
                this.visibleFull = false
                if(val.query.openDialog){
                    this.openDialog();
                }
                if(val.query.goTo && (val.query.goTo === 'home' || val.query.goTo === 'cursos' || val.query.goTo === 'contato' || val.query.goTo === 'quem-somos')){
                    this.scrollToSection(val.query.goTo);
                }
            }
        }
    },
    components: {
        Dialog,
        InputText,
        Toast,
        Password,
        AppPlanos,
        AppMetodologia,
        AppPQEscolher,
        AppDiferencial,
        AppExperiencia,
        ProgressSpinner,
        AppNossaHistoria,
        AppMissaoVisaoValores,
        AppProfessores,
        AppRodape,
        Sidebar
    }
}
</script>

<style>

.home-container {
    background-color: #DFE6EC;
    width: 100%;
    padding: 50px 10% 0 10%;
}

.home-title {
    font-family: 'Montserrat Black 900', sans-serif;
    font-weight: bold;
    font-size: 35px;
}

.panel-navigation {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.panel-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    position: relative;
}

.panel-container::-webkit-scrollbar {
    display: none;
}

.panel-cards {
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 15px;
    width: 400px;
    flex: 0 0 auto;
    margin-right: 20px;
}

.scroll-button {
    background-color: #E8E8EB;
    color: black;
    border: none;
    width: 40px;
    height: 40px;
    padding-top: 3px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.scroll-button-left {
    left: calc(10% - 60px);
}

.scroll-button-right {
    right: calc(10% - 60px);
}

.scroll-button:focus {
    outline: none;
}

.home-topo {
    background-color: #DFE6EC;
    height: 100px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0 10%;
}

.home-topo-1 {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    z-index: 2;
}

.home-topo-1 img {
    cursor: pointer;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 30px;
    height: 30px;
}

.carousel-control-prev,
.carousel-control-next {
    width: 7%;
    opacity: 0.8;
}

.p-dialog-content {
    padding: 0 !important;
    border-radius: 10px !important;
}

.p-dialog-header {
    display: none !important;
}

.p-inputtext {
    width: 100%;
    padding: .75rem .75rem;
}

.p-error {
    font-weight: bold;
    color: red;
    text-align: left;
    width: 100%;
    font-size: 15px;
}

.p-toast{
    opacity: 1 !important;
}

</style>

<style scoped>
h6 {
    font-size: 15px;
}

.home-topo-2 {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.position-home-topo-2 {
    z-index: 2;
    display: flex;
    position: relative;
    bottom: 7px;
}

.minha-conta-button {
    background-color: #DFE6EC;
    height: 30px;
    border-radius: 10px;
    width: 160px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 20px;
    z-index: 2;
}

.minha-conta-button:hover {
    cursor: pointer;

}

.m-r-icon {
    margin-right: 10px;
}

.icon-midia {
    margin-left: 5px;
}

.icon-midia:hover {
    cursor: pointer;
}

.menu-container {
    height: 50px;
    background-color: #00A3B4;
    padding-left: 10%;
    display: flex;
}

.button-style {
    height: 100%;
    padding: 0 15px;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: white;
    z-index: 2;
}

.button-style:hover {
    background-color: #00AFF0;
}

.detalhe-branco {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
}









.dialog-style {
    font-family: 'Montserrat Regular 400';
    width: 90vw;
    max-width: 1200px;
    height: 70vh;
    display: flex;
}

.block-dialog-login {
    width: 50%;
    background-color: #00A3B4;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 10%;
}

.block-dialog-register {
    width: 50%;
    background-color: white;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 10%;
}

.title-login {
    font-size: 30px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
}

.title-register {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

.button-login {
    height: 40px;
    padding: 0 15px;
    border: 2px solid white;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100px;
    color: white;
    margin: 25px auto 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-login:hover {
    background-color: white;
    color: #00A3B4;
    cursor: pointer;
}

.button-register {
    height: 40px;
    padding: 0 15px;
    border: 2px solid black;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 150px;
    color: black;
    margin: 25px auto 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-register:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.forgot-password-dialog {
    font-style: italic;
    color: white;
    margin-top: 5px;
}

.forgot-password-dialog:hover {
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .button-minha-conta{
        height: 40px;
        padding: 0 15px;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 40px;
        border: 2px solid rgb(161, 161, 161);
        color: black;
        background-color: #DFE6EC;
        border-radius: 20px;
        margin-right: 15px;
        position: relative;
        top: 8px;
    }

    .button-style-ham{
        height: 40px;
        padding: 0 15px;
        border: 2px solid #00A3B4;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 40px;
        color: #00A3B4;
        border-radius: 20px;
        font-weight: bold;
        position: relative;
        top: 8px;
    }

    .button-style-ham:hover{
        background-color: #00A3B4;
        color: white;
        cursor: pointer;
    }

    .dialog-style {
        width: 90vw;
        max-width: 600px;
        height: auto;
        display: block;
    }

    .block-dialog-login,
    .block-dialog-register {
        height: 400px;
    }
}

@media (max-width: 600px) {
    .minha-conta-button {
        margin: 0 0 10px 0;
        top: 0;
    }
}

</style>

<style>

@media (max-width: 1024px) {

    .home-topo-1 img{
        width: 150px;
        position: relative;
        top: 3px;
    }

    .home-topo{
        height: 80px;
        border-bottom: 2px solid #b6b6b6;
    }

    .scroll-button{
        display: none;
    }

    .home-title{
        font-size: 25px;
    }
}

@media (max-width: 500px) {
    
    .panel-cards {
        width: 80%;
    }

}

</style>