<template>
    <div style="background-color: #F2F2F2;">
        <Toast />

        <div class="home-topo">
            <div class="home-topo-1">
                <img @click="$router.push('/homeCourse');" src="@/assets/logo-home.svg">
            </div>

            <div class="home-topo-2">
                <img class="detalhe-branco" src="@/assets/detalhe-branco.svg">
                <div class="position-home-topo-2">
                    <div style="position: relative; top: 5px; color: #696969;">{{ userName }}</div>
                    <div @click="toggle" style="cursor: pointer;">
                        <Avatar :label="userName.charAt(0)" class="ml-3" style="background-color: #2196F3; color: #ffffff" shape="circle"  />
                    </div>

                    <OverlayPanel ref="op" appendTo="body" style="width: 350px;">
                        <div style="border-bottom: 1px solid #cccccc; display: flex; padding-bottom: 15px; margin-bottom: 15px;">
                            <div style="width: 30%; padding-left: 20px;">
                                <Avatar size="large" :label="userName.charAt(0)" style="background-color: #2196F3; color: #ffffff" shape="circle"  />
                            </div>
                            <div style="width: 70%; padding-right: 20px;">
                                <div style="font-weight: bold;">{{ userName }}</div>
                                <div>{{ userEmail }}</div>
                            </div>
                        </div>
                        <div style="justify-content: space-between; display: flex;">
                            <Button label="Editar Dados" style="height: 40px;" @click="changeData()" class="p-button-secondary p-button-sm" />
                            <Button label="Sair" style="height: 40px;" @click="goOut()" class="p-button-danger p-button-sm" />
                        </div>
                    </OverlayPanel>
                </div>
            </div>
        </div>

        <div class="menu-container">
            <div class="menu-container-buttons">
                <div class="item">
                    <div class="item-title" @click="nextPage('tecnicas-de-estudo')">METODOLOGIA DE ESTUDO</div>
                    <div class="dropdown">
                        <div class="aba-normal" @click="nextPage('tecnicas-de-estudo')">Metodologia de Estudo</div>
                        <div class="aba-normal" @click="nextPage('inteligencia-emocional')">Inteligência emocional</div>
                    </div>
                </div>
                <div class="item">
                    <div class="item-title" @click="nextPage('disciplinas', 'Ética')">DISCIPLINAS</div>
                    <div class="dropdown">
                        <div class="bloco">
                            <div class="aba-normal" @click="nextPage('disciplinas', 'Ética')">Bloco A</div>
                            <div class="drop-dropdown">
                                <div @click="nextPage('disciplinas', 'Bloco A - Ética')">Ética</div>
                                <div @click="nextPage('disciplinas', 'Bloco A - Civil')">Civil</div>
                                <div @click="nextPage('disciplinas', 'Bloco A - Código de Processo Civil')">Código de Processo Civil</div>
                                <div @click="nextPage('disciplinas', 'Bloco A - Constitucional')">Constitucional</div>
                                <div @click="nextPage('disciplinas', 'Bloco A - Penal')">Penal</div>
                                <div @click="nextPage('disciplinas', 'Bloco A - Processo Penal')">Processo Penal</div>
                            </div>
                        </div>
                        <div class="bloco">
                            <div class="aba-normal" @click="nextPage('disciplinas', 'Trabalho')">Bloco B</div>
                            <div class="drop-dropdown">
                                <div @click="nextPage('disciplinas', 'Bloco B - Trabalho')">Trabalho</div>
                                <div @click="nextPage('disciplinas', 'Bloco B - Processo do Trabalho')">Processo do Trabalho</div>
                                <div @click="nextPage('disciplinas', 'Bloco B - Tributário')">Tributário</div>
                                <div @click="nextPage('disciplinas', 'Bloco B - Empresarial')">Empresarial</div>
                                <div @click="nextPage('disciplinas', 'Bloco B - Administrativo')">Administrativo</div>
                            </div>
                        </div>
                        <div class="bloco">
                            <div class="aba-normal" @click="nextPage('disciplinas', 'Humanos')">Bloco C</div>
                            <div class="drop-dropdown">
                                <div @click="nextPage('disciplinas', 'Bloco C - Humanos')">Humanos</div>
                                <div @click="nextPage('disciplinas', 'Bloco C - Internacional')">Internacional</div>
                                <div @click="nextPage('disciplinas', 'Bloco C - ECA')">ECA</div>
                                <div @click="nextPage('disciplinas', 'Bloco C - Ambiental')">Ambiental</div>
                                <div @click="nextPage('disciplinas', 'Bloco C - CDC')">CDC</div>
                                <div @click="nextPage('disciplinas', 'Bloco C - Filosofia')">Filosofia</div>
                                <div @click="nextPage('disciplinas', 'Bloco C - Eleitoral')">Eleitoral</div>
                                <div @click="nextPage('disciplinas', 'Bloco C - Financeiro')">Financeiro</div>
                                <div @click="nextPage('disciplinas', 'Bloco C - Previdenciário')">Previdenciário</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="item-title" @click="nextPage('exames-FGV')">MATERIAL DE ESTUDOS</div>
                    <div class="dropdown">
                        <div class="aba-normal" @click="downloadEBook()">E-Book (Apostila)</div>
                        <div class="aba-normal" @click="nextPage('exames-FGV')">Exames Anteriores FGV</div>
                    </div>
                </div>
                <div class="item">
                    <div class="item-title" @click="nextPage('simulado')">SIMULADOS</div>
                    <div class="dropdown">
                        <div class="aba-normal" @click="nextPage('simulado')">Simulado 1ª Fase</div>
                        <div class="aba-normal" @click="nextPage('analise-de-desempenho')">Análise de Desempenho</div>
                    </div>
                </div>
            </div>
            <div class="menu-container-icons">
                <img class="icon-midia" src="@/assets/social/instagram.svg">
                <img class="icon-midia" src="@/assets/social/youtube.svg">
                <img class="icon-midia" src="@/assets/social/facebook.svg">
                <img class="icon-midia" src="@/assets/social/linkedin.svg">
            </div>
        </div>

        <router-view />

        <div v-if="isHomeCourseRoute" class="container">
            <div class="grid-container">
                <div class="grid-item" style="cursor: pointer;" @click="$router.push('/homeCourse/tecnicas-de-estudo');">
                    <img src="@/assets/homeCourse/tecnicas-de-estudo.png" alt="Metodologia de Estudo">
                    <p>Metodologia de Estudo</p>
                </div>
                <div class="grid-item" style="cursor: pointer;" @click="$router.push('/homeCourse/inteligencia-emocional');">
                    <img src="@/assets/homeCourse/auto-controle.png" alt="Auto Controle">
                    <p>Inteligência Emocional</p>
                </div>
                <div class="grid-item" style="cursor: pointer;" @click="$router.push('/homeCourse/disciplinas/Ética');">
                    <img src="@/assets/homeCourse/disciplinas.png" alt="Disciplinas">
                    <p>Disciplinas</p>
                </div>
                <div class="grid-item" style="cursor: pointer;" @click="downloadEBook()">
                    <img src="@/assets/homeCourse/materiais-de-estudo.png" alt="Materiais de Estudo">
                    <p>Materiais de Estudo</p>
                </div>
                <div class="grid-item" style="cursor: pointer;" @click="$router.push('/homeCourse/exames-FGV');">
                    <img src="@/assets/homeCourse/exames-anteriores-FGV.png" alt="Exames Anteriores FGV">
                    <p>Exames Anteriores FGV</p>
                </div>
                <div class="grid-item" style="cursor: pointer;" @click="$router.push('/homeCourse/simulado');">
                    <img src="@/assets/homeCourse/simulados.png" alt="Simulados">
                    <p>Simulados</p>
                </div>
                <div class="grid-item" style="cursor: pointer;" @click="$router.push('/homeCourse/analise-de-desempenho');">
                    <img src="@/assets/homeCourse/analise-de-desempenho.png" alt="Análise de Desempenho">
                    <p>Análise de Desempenho</p>
                </div>
                <div class="grid-item" style="cursor: pointer;" @click="dialogAtendimento = true">
                    <img src="@/assets/homeCourse/atendimento.png" alt="Atendimento">
                    <p>Atendimento</p>
                </div>
            </div>
        </div>

        <b-modal v-model="dialogAtendimento" size="lg" centered class="custom-dialog">
            <div style="overflow: auto;">
                Entre em contato com (41) 9 8771-0044
            </div>
            <template v-slot:modal-footer>
                <b-button variant="secondary" @click="dialogAtendimento = false">Fechar</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import Button from 'primevue/button/Button';
import apiClient from '@/services/api';
import Avatar from 'primevue/avatar';
import OverlayPanel from 'primevue/overlaypanel';
import Toast from 'primevue/toast/Toast';

export default {
    components: {
        Button, Avatar, OverlayPanel, Toast
    },
    data() {
        return {
            dialogAtendimento: false,
            videoUrl: null,
            userName: localStorage.getItem('name'),
            userEmail: localStorage.getItem('email')
        }
    },
    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        nextPage(route, params){
            if(route != this.$route.path.toString().replace("/homeCourse/", "") && route != 'disciplinas'){
                this.$router.push(`/homeCourse/${route}`)
                return
            }

            if(this.$route.params.item != params && route === 'disciplinas'){
                this.$router.push({ name: 'disciplinas', params: { item: params } });
                return
            }
        },
        async downloadEBook(){
            try {
                let response = await apiClient.get(`/file/download?key=FGV/E-Book.pdf`, {
                    responseType: 'blob'
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `E-Book.pdf`);

                document.body.appendChild(link);
                link.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro', life: 3000 });
            }
        },
        goOut(){
            localStorage.clear();
            window.location.reload();
        },
        changeData(){
            this.$refs.op.hide();
            this.$router.push('/homeCourse/changeData');
        }
    },
    computed: {
        isHomeCourseRoute() {
            return this.$route.path === '/homeCourse';
        }
    }
};
</script>

<style scoped>

.container {
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 100px;
    margin: 0 auto;
    width: 900px;
    justify-content: center;
    align-items: center;
}

.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.grid-item img {
    width: 90px;
    margin-bottom: 10px;
}

.grid-item p {
    margin: 0;
    font-size: 14px;
    color: #333;
}












.home-topo {
    background-color: #00A3B4;
    height: 100px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0 10%;
}

.home-topo-1 {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    z-index: 2;
}

.home-topo-1 img{
    cursor: pointer;
    filter: brightness(0) invert(1);
}

.home-topo-2 {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.position-home-topo-2 {
    z-index: 2;
    display: flex;
    position: relative;
    bottom: 7px;
}

.m-r-icon{
    margin-right: 10px;
}

.icon-midia{
    margin-left: 5px;
}

.icon-midia:hover{
    cursor: pointer;
}

.detalhe-branco {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
}

.menu-container{
    height: 50px;
    background-color: #002133;
    padding: 0 10%;
    align-items: center;
    color: white;
    justify-content: space-between;
    display: flex;
}

.menu-container-buttons{
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
}





.item {
    position: relative;
    z-index: 2;
    height: 100%;
    padding: 0 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.item:hover {
    background-color: #00AFF0;   
}

.item-title{
    height: 100%; 
    width: 100%; 
    display: flex; 
    justify-content: center; 
    flex-direction: column;
}

.dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #2E2F31;
    width: 250px;
    color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 3;
}

.item:hover .dropdown {
    display: block;
}

.bloco{
    height: calc(100% / 3);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bloco:hover{
    color: #00AFF0;
}

.drop-dropdown{
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    background-color: #2E2F31;
    width: 300px;
    color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 3;
}

.drop-dropdown div{
    height: 50px;
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid rgb(78, 78, 78);
}

.drop-dropdown div:hover{
    color: #00AFF0;
}

.bloco:hover .drop-dropdown{
    display: block;
}

.aba-normal{
    height: 50px;
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid rgb(78, 78, 78);
}

.aba-normal:hover{
    color: #00AFF0;
}

</style>

<style>

.p-overlaypanel{
    left: calc(90% - 350px) !important;
}

</style>