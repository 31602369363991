<template>
    <div style="background-color: white;">
        <Toast />

        <div class="container" v-if="page === 0">
            <div class="title">Cadastro</div>
            <h6>Insira suas informações para que possamos liberar o simulado para você.</h6>

            <div class="item-style">
                <span>Email *</span><br>
                <InputText class="w-100" v-model="form.email" />
                <div v-if="!isValidForm && isValidValue(form.email)" class="p-error">Campo obrigatório.</div>
            </div>

            <div class="item-style">
                <span>Nome *</span><br>
                <InputText class="w-100" v-model="form.name" />
                <div v-if="!isValidForm && isValidValue(form.name)" class="p-error">Campo obrigatório.</div>
            </div>

            <div class="item-style">
                <span>Cpf *</span><br>
                <InputMask class="w-100" mask="999.999.999-99"  v-model="form.cpf" />
                <div v-if="!isValidForm && isValidValue(form.cpf)" class="p-error">Campo obrigatório.</div>
            </div>

            <div class="item-style">
                <span>Telefone *</span><br>
                <InputMask class="w-100" mask="(99) 99999-9999" v-model="form.fone" />
                <div v-if="!isValidForm && isValidValue(form.fone)" class="p-error">Campo obrigatório.</div>
            </div>

            <div class="item-style">
                <span>Cep *</span><br>
                <InputMask class="w-100" mask="99999-999" v-model="form.cep" />
                <div v-if="!isValidForm && isValidValue(form.cep)" class="p-error">Campo obrigatório.</div>
            </div>

            <div class="button-style" @click="send">Seguir</div>
        </div>

        <div style="padding: 0 10%;" v-if="page === 1">
            <div style="border-bottom: 1px solid #cccccc; padding: 15px 0 0 15px; height: 50px;">Preencha o gabarito abaixo conforme suas respostas na prova:</div>
            <div style="display: flex; width: 100%; border-bottom: 1px solid #cccccc;">
                <div style="width: 60%; height: 500px; overflow: auto; border-right: 1px solid #cccccc;">
                    <div v-for="(question, index) in questions" :key="question.id" style="margin: 20px;">
                        <span style="font-weight: bold;">{{ index + 1 }} - </span> {{ question.question }}

                        <br><span style="font-weight: bold;">a. </span>{{ question.a }}
                        <br><span style="font-weight: bold;">b. </span>{{ question.b }}
                        <br><span style="font-weight: bold;">c. </span>{{ question.c }}
                        <br><span style="font-weight: bold;">d. </span>{{ question.d }}
                    </div>
                </div>
                <div style="width: 40%; height: 500px; overflow: auto;">
                    <div v-for="(question, index) in results" :key="question.id" style="margin: 20px;">
                        <div style="display: flex;">
                            <span style="font-weight: bold; margin-right: 10px;">{{ index + 1 }} - </span>
    
                            <RadioButton :id="'a-' + index" value="a" v-model="results[index].awnser" />
                            <label :for="'a-' + index" style="margin: 0 10px 0 3px;">A</label>
    
                            <RadioButton :id="'b-' + index" value="b" v-model="results[index].awnser" />
                            <label :for="'b-' + index" style="margin: 0 10px 0 3px;">B</label>
    
                            <RadioButton :id="'c-' + index" value="c" v-model="results[index].awnser" />
                            <label :for="'c-' + index" style="margin: 0 10px 0 3px;">C</label>
    
                            <RadioButton :id="'d-' + index" value="d" v-model="results[index].awnser" />
                            <label :for="'d-' + index" style="margin: 0 10px 0 3px;">D</label>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 120px; padding: 40px;">
                <div class="button-style-enviar" @click="sendSimulate">Enviar</div>
            </div>
        </div>

        <div v-if="page === 2" style="display: flex; justify-content: center; align-items: center; display: flex; height: calc(100vh - 155px);">
            <div style="background-color: white; border-radius: 5px; height: 200px; width: 340px; margin: 20px; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                <div style="height: 67%; border-bottom: 1px solid #cccccc; padding: 5%;">
                    <div style="font-weight: bold; font-size: 19px; margin-bottom: 10px;">Simulado - 1ª Fase</div>
                    <div style="margin-bottom: 8px;"><i class="pi pi-pencil" /> O resultado do simulado será enviado para seu WhatsApp</div>
                </div>
    
                <div class="button-style" @click="$router.push('/');">Voltar</div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from '@/services/api';
import InputMask from 'primevue/inputmask/InputMask';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast/Toast';
import RadioButton from 'primevue/radiobutton';

export default {
    components: {
        Toast,
        InputText,
        InputMask,
        RadioButton
    },
    data() {
        return {
            isValidForm: true,
            form: {
                email: '',
                name: '',
                cpf: '',
                fone: '',
                cep: ''
            },
            page: 0,
            questions: null,
            results: null,
            userId: null
        };
    },
    methods: {
        async send(){
            for (let key in this.form) {
                if (this.form[key] === '') {
                    this.isValidForm = false;
                    this.$toast.add({ severity: 'warn', summary: "Alerta", detail: `Preencha todos os campos!`, life: 3000 });
                    return;
                }
            }

            try {
                let response = await apiClient.post('/out/simulate/register', this.form);
                this.userId = response.data.userId;
                this.getSimulate();
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: "Erro interno no servidor", life: 3000 });
            }
        },
        async getSimulate(){
            try {
                let response = await apiClient.get('/out/simulate');
                this.questions = response.data
                this.results = this.questions.map(element => ({id: element.id.toString(), awnser: ''}))
                this.page = 1
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: "Erro interno no servidor", life: 3000 });
            }
        },
        async sendSimulate(){
            try {
                let response = await apiClient.post('/out/simulate', { awnsers: this.results, id: this.userId });
                this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: "Simulado enviado com sucesso", life: 3000 });
                console.log(response.data);
                this.page = 2
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: "Não foi possível enviar o Simulado", life: 3000 });
            }
        }
    }
};
</script>

<style scoped>

.container{
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 30px;
}

.title{
    padding-top: 30px;
    margin: 0 0 10px 0;
    font-size: 25px;
    font-weight: bold;
}

.item-style{
    text-align: left;
    margin-top: 15px;
}

.w-100{
    width: 100%;
}

.button-style{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 150px;
    color: #00A3B4;
    margin: 12px auto 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}

.button-style-enviar{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 200px;
    color: #00A3B4;
    margin: 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style-enviar:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}

</style>