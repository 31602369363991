<template>
    <div style="padding: 0 10%;">
        <div style="border-bottom: 1px solid #cccccc; padding: 15px 0 0 15px; height: 50px;">Exames anteriores FGV</div>
        <div style="display: flex; flex-wrap: wrap;">
            <div v-for="item in exames" :key="item.id" style="background-color: white; border-radius: 5px; height: 220px; width: 340px; margin: 20px; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                <div style="height: 70%; border-bottom: 1px solid #cccccc; padding: 5%;">
                    <div style="font-weight: bold; font-size: 19px; margin-bottom: 10px;">{{ item.name }}</div>
                    <div style="margin-bottom: 8px;"><i class="pi pi-pencil" /> Tipos de questões: Múltipla escolha de A a D</div>
                    <div><i class="pi pi-book" /> Instituição: OAB</div>
                </div>
    
                <div class="button-style" @click="download(item.value)">Download <i class="pi pi-download ml-2" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from '@/services/api';

export default {
    components: {
        
    },
    data() {
        return {
            exames: [
                { id: 12, name: 'FGV 41', value: '41' },
                { id: 11, name: 'FGV 40', value: '40' },
                { id: 10, name: 'FGV 39', value: '39' },
                { id: 9, name: 'FGV 38', value: '38' },
                { id: 8, name: 'FGV 37', value: '37' },
                { id: 7, name: 'FGV 36', value: '36' },
                { id: 6, name: 'FGV 35', value: '35' },
                { id: 5, name: 'FGV 34', value: '34' },
                { id: 4, name: 'FGV 33', value: '33' },
                { id: 3, name: 'FGV 32', value: '32' },
                { id: 2, name: 'FGV 31', value: '31' },
                { id: 1, name: 'FGV 30', value: '30' },
            ]
        };
    },
    methods: {
        async download(val){
            try {
                // Fazendo a requisição para obter o arquivo PDF
                let response = await apiClient.get(`/file/download?key=FGV/${val}.pdf`, {
                    responseType: 'blob'
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${val}.pdf`);

                document.body.appendChild(link);
                link.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);

                console.log('Download realizado com sucesso!');
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro', life: 3000 });
            }
        }
    }
};
</script>

<style scoped>
.button-style{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 150px;
    color: #00A3B4;
    margin: 12px auto 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}
</style>