<template>
    <div style="background-color: white;">
        <Toast />
        <div style="display: flex; padding: 0 10%;">
            <div style="width: 50%; border-right: 1px solid #cccccc;" >
                <div class="container">
                    <div class="title">Altere seus dados</div>
    
                    <!-- Nome -->
                    <div class="item-style">
                        <span for="name">Nome Completo *</span><br>
                        <InputText class="w-100" v-model="formChangeData.name" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.name)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- Telefone -->
                    <div class="item-style">
                        <span for="fone">Telefone *</span><br>
                        <InputMask class="w-100" mask="(99) 99999-9999" v-model="formChangeData.fone" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.fone)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- Data de Nascimento -->
                    <div class="item-style">
                        <span for="data_nasc">Data de Nascimento *</span><br>
                        <InputMask class="w-100" mask="99/99/9999" v-model="formChangeData.data_nasc" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.data_nasc)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- Sexo -->
                    <div class="item-style">
                        <span for="sexo">Sexo *</span><br>
                        <InputText class="w-100" v-model="formChangeData.sexo" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.sexo)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- CEP -->
                    <div class="item-style">
                        <span for="cep">CEP *</span><br>
                        <InputMask class="w-100" mask="99999-999" v-model="formChangeData.cep" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.cep)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <div class="button-style" @click="consultarCEP">Consultar CEP</div>
                    <!-- Estado -->
                    <div class="item-style">
                        <span for="estado">Estado *</span><br>
                        <InputText class="w-100" v-model="formChangeData.estado" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.estado)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- Cidade -->
                    <div class="item-style">
                        <span for="cidade">Cidade *</span><br>
                        <InputText class="w-100" v-model="formChangeData.cidade" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.cidade)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- Rua -->
                    <div class="item-style">
                        <span for="rua">Rua *</span><br>
                        <InputText class="w-100" v-model="formChangeData.rua" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.rua)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- Número -->
                    <div class="item-style">
                        <span for="num">Número *</span><br>
                        <InputText class="w-100" v-model="formChangeData.num" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.num)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- BAIRRO -->
                    <div class="item-style">
                        <span for="bairro">Bairro *</span><br>
                        <InputText class="w-100" v-model="formChangeData.bairro" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.bairro)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- COMPLEMENTO -->
                    <div class="item-style">
                        <span for="complemento">Complemento *</span><br>
                        <InputText class="w-100" v-model="formChangeData.complemento" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.complemento)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- Instituição de Ensino -->
                    <div class="item-style">
                        <span for="instituicao_de_ensino">Instituição de Ensino *</span><br>
                        <InputText class="w-100" v-model="formChangeData.instituicao_de_ensino" />
                        <div v-if="!isValidForm && isValidValue(formChangeData.instituicao_de_ensino)" class="p-error">Campo obrigatório.</div>
                    </div>
                    <!-- Botão de Submit -->
                    <div class="button-style" @click="submitChangeData">Confirmar</div>
                </div>
            </div>
            <div style="width: 50%;">
                <div class="container">
                    <div class="title">Troque sua senha</div>
                    <div>
                        <div class="item-style">
                            <span for="login">Senha atual *</span><br>
                            <Password class="w-100" v-model="formChangePassword.oldPassword" :feedback="false" toggleMask/>
                            <div v-if="!isValidFormPassword && isValidValue(formChangePassword.oldPassword)" class="p-error">Campo obrigatório.</div>
                        </div>
                        <div class="item-style">
                            <span for="password">Nova senha *</span><br>
                            <Password class="w-100" v-model="formChangePassword.newPassword" :feedback="false" toggleMask/>
                            <div v-if="!isValidFormPassword && isValidValue(formChangePassword.newPassword)" class="p-error">Campo obrigatório.</div>
                        </div>
                        <div class="item-style">
                            <span>Repita a Senha *</span><br>
                            <Password class="w-100" v-model="repeatPassword" :feedback="false" toggleMask/>
                            <div v-if="!isValidFormPassword && isValidValue(repeatPassword)" class="p-error">Campo obrigatório.</div>
                        </div>
                        <div class="button-style" @click="submitNewPassowrd">Confirmar</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from '@/services/api'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import Password from 'primevue/password/Password'
import InputMask from 'primevue/inputmask';

export default {
    components: {
        InputText,
        Toast,
        Password,
        InputMask
    },
    created() {
        this.getData();
    },
    data() {
        return {
            formChangeData: {
                userId: '',
                name: '',
                fone: '',
                data_nasc: '',
                sexo: '',
                estado: '',
                cidade: '',
                rua: '',
                num: '',
                cep: '',
                instituicao_de_ensino: '',
                bairro: '',
                complemento: '',
            },
            formChangePassword: {
                userId: '',
                oldPassword: '',
                newPassword: ''
            },
            isValidFormPassword: true,
            isValidForm: true,
            repeatPassword: ''
        };
    },
    methods: {
        async submitChangeData() {
            for (let key in this.formChangeData) {
                if (this.formChangeData[key] === '') {
                    this.isValidForm = false;
                    this.$toast.add({ severity: 'warn', summary: "Alerta", detail: `Preencha todos os campos!`, life: 3000 });
                    return;
                }
            }

            try {
                await apiClient.post('/alterationData', this.formChangeData);
                this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Alterado com sucesso', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao Alterar dados', life: 3000 });
            }
        },
        async getData(){
            try {
                let userId = localStorage.getItem('userId');
                let response = await apiClient.post('/userDetails', {userId: userId});
                this.formChangeData = {
                    userId: userId,
                    name: response.data.name,
                    fone: response.data.fone,
                    data_nasc: `${response.data.data_nasc.split("-")[2]}/${response.data.data_nasc.split("-")[1]}/${response.data.data_nasc.split("-")[0]}`,
                    sexo: response.data.sexo,
                    estado: response.data.estado,
                    cidade: response.data.cidade,
                    rua: response.data.rua,
                    num: response.data.num,
                    cep: response.data.cep,
                    instituicao_de_ensino: response.data.instituicao_de_ensino,
                    bairro: response.data.bairro,
                    complemento: response.data.complemento,
                }
                this.formChangePassword.userId = userId
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao Carregar Dados', life: 3000 });
            }
        },
        async submitNewPassowrd(){
            for (let key in this.formChangePassword) {
                if (this.formChangePassword[key] === '') {
                    this.isValidFormPassword = false;
                    this.$toast.add({ severity: 'warn', summary: "Alerta", detail: `Preencha todos os campos!`, life: 3000 });
                    return;
                }
            }

            if(this.repeatPassword != this.formChangePassword.newPassword){
                this.$toast.add({ severity: 'warn', summary: "Alerta", detail: `As senhas devem ser iguais!`, life: 3000 });
                return
            }

            try {
                await apiClient.post('/alterationPassword', this.formChangePassword);
                this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Alterado com sucesso', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Verifique se a senha atual está correta', life: 3000 });
            }
        },
        async consultarCEP() {
            const cep = this.formChangeData.cep.replace(/\D/g, '');
            try {
                const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
                const data = await response.json();
                
                if (data.erro) {
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível encontrar o CEP', life: 3000 });
                    this.formChangeData.rua = '';
                    this.formChangeData.bairro = '';
                    this.formChangeData.cidade = '';
                    this.formChangeData.estado = '';
                    this.errorMessage = '';
                } else {
                    this.formChangeData.rua = data.logradouro;
                    this.formChangeData.bairro = data.bairro;
                    this.formChangeData.cidade = data.localidade;
                    this.formChangeData.estado = data.uf;
                    this.errorMessage = '';
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível encontrar o CEP', life: 3000 });
                this.formChangeData.rua = '';
                this.formChangeData.bairro = '';
                this.formChangeData.cidade = '';
                this.formChangeData.estado = '';
            }
        },
        isValidValue(val){
            return val.trim() === ''
        }
    }
};
</script>

<style scoped>

.container{
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.item-style{
    text-align: left;
    margin-top: 15px;
}

.w-100{
    width: 100%;
}

.title{
    margin: 30px 0 10px 0;
    font-size: 25px;
    font-weight: bold;
}

.button-style{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 150px;
    color: #00A3B4;
    margin: 25px auto 0 auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}

.copyright{
    width: 100%;
    padding: 0 10%;
    text-align: center;
    margin: 20px 0;
}

.text-termos{
    font-size: 14px;
    margin: 25px 20px 0 20px;
}

.button-style{
    height: 40px;
    padding: 0 15px;
    border: 2px solid #00A3B4;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 150px;
    color: #00A3B4;
    margin: 25px auto 25px auto;
    border-radius: 20px;
    font-weight: bold;
}

.button-style:hover{
    background-color: #00A3B4;
    color: white;
    cursor: pointer;
}

</style>