<template>
    <div>
        <div class="home-container">
            <div style="color: #B3925E; font-style: italic; text-decoration: underline; text-decoration-color: #cccccc;">QUEM SOMOS:</div>
            <div class="home-title">Nossa História</div>
            <div style="width: 100%; height: 3px; background-color: #CCCCCC; margin: 40px 0px;"></div>
            
            <div style="font-family: 'Montserrat Black 900'; font-size: 23px;">Transformando Desafios em Conquistas: <br> Uma Jornada de Dedicação e Excelência</div>
            <div style="height: 3px; background-color: #B3925E; width: 80px; margin: 12px 0;"></div>
            <div style="display: flex;" v-if="screenWidth > 1024">
                <div style="flex: 1; margin-right: 30px;">
                    <span style="font-weight: lighter;">
                        A história da Aprojur começou no
                        primeiro semestre de 2024, em
                        Curitiba, capital do Paraná.<br><br>

                        Foi nesse contexto que o mentor
                        do curso, Fabio Coimbra, sentiu
                        um profundo desejo de lecionar e
                        compartilhar o conhecimento e
                    </span>
                </div>
                <div style="flex: 1; margin: 0 30px;">
                    <span style="font-weight: lighter;">
                        as técnicas que desenvolveu ao
                        longo de suas tentativas de superar 
                        o exame da OAB.<br><br>

                        Esse desejo ardente de tornar o
                        conhecimento acessível aos alunos
                        floresceu após Fabio receber
                        feedbacks gratificantes de ami-
                    </span>
                </div>
                <div style="flex: 1; margin-left: 30px;">
                    <span style="font-weight: lighter;">
                        gos que ele havia ajudado nessa
                        Jornada.<br><br>

                        Atualmente, a equipe conta com
                        15 professores dedicados, que
                        compartilham o mesmo entusiasmo, 
                        determinação e expertise
                        para guiar os alunos rumo à aprovação.
                    </span>
                </div>
            </div>
            <div v-else>
                <span style="font-weight: lighter;">
                        A história da Aprojur começou no
                        primeiro semestre de 2024, em
                        Curitiba, capital do Paraná.<br><br>

                        Foi nesse contexto que o mentor
                        do curso, Fabio Coimbra, sentiu
                        um profundo desejo de lecionar e
                        compartilhar o conhecimento e as 
                        técnicas que desenvolveu ao
                        longo de suas tentativas de superar 
                        o exame da OAB.<br><br>

                        Esse desejo ardente de tornar o
                        conhecimento acessível aos alunos
                        floresceu após Fabio receber
                        feedbacks gratificantes de amigos 
                        que ele havia ajudado nessa
                        Jornada.<br><br>

                        Atualmente, a equipe conta com
                        15 professores dedicados, que
                        compartilham o mesmo entusiasmo, 
                        determinação e expertise
                        para guiar os alunos rumo à aprovação.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        };
    },
    methods: {

    },
    components: {
        
    },
    props: ['screenWidth']
}
</script>