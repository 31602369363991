<template>
    <div>
        <Toast />
        <div class="home-container" :class="{'home-container-select': showMenu}">
            <div class="home-title" v-if="!showMenu">Encontre seu curso preparatório <br> para a OAB na Aprojur!</div>
            <div class="home-title" v-else>Selecione seu curso!</div>
            <div style="width: 135px; height: 3px; background-color: #B99660; margin: 15px 0;"></div>
            <div style="font-family: 'Montserrat Regular 400'">Curso preparatório para o Exame de Ordem, feito sob medida para você. <br> Materiais de apoio, técnicas avançadas de estudo, análises de sempenho e <br> mentoria AO VIVO, tudo reunido em um só lugar!</div>
        </div>

        <div class="panel-navigation" :class="{'panel-navigation-select': showMenu}">
            <div class="panel-container" ref="panelContainer">
                <div class="panel-cards" @click="selectProduct('2')" style="margin-left: 10vw; cursor: pointer;"><img src="@/assets/planos/Card 01.png" width="100%"></div>
                <div class="panel-cards" @click="selectProduct('1')" style="cursor: pointer;"><img src="@/assets/planos/Card 02.png" width="100%"></div>
                <div class="panel-cards" @click="selectProduct('3')" style="margin-right: 10vw; cursor: pointer;"><img src="@/assets/planos/Card 03.png" width="100%"></div>
            </div>
            <button @click="scrollLeft" class="scroll-button scroll-button-left"><i class="pi pi-chevron-left" /></button>
            <button @click="scrollRight" class="scroll-button scroll-button-right"><i class="pi pi-chevron-right" /></button>
        </div>
        
    </div>
</template>

<script>
import Toast from 'primevue/toast/Toast';

export default {
    components: {
        Toast
    },
    data() {
        return {
            SCROLL_PX: 420,
            showMenu: false
        };
    },
    mounted() {
        if(this.$route.path === '/plans'){
            this.showMenu = true
        }
    },
    methods: {
        scrollLeft() {
            this.$refs.panelContainer.scrollBy({
                left: -this.SCROLL_PX,
                behavior: 'smooth'
            });
        },
        scrollRight() {
            this.$refs.panelContainer.scrollBy({
                left: this.SCROLL_PX,
                behavior: 'smooth'
            });
        },
        selectProduct(val) {
            this.$router.push({ name: 'details', query: { plan: val } });
        }
    }
};
</script>

<style scoped>

.home-container-select{
    padding-top: 30px;
}

.panel-navigation-select{
    padding-bottom: 30px;
}

</style>